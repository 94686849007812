/** @format */

import React, {useEffect, useRef, useState} from "react";
import Calendar from "../../component/tutor/Calendar";
import {theme} from "antd";
import {useInput} from "../../hook/input-hook";
import {
  ExclamationTriangleIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {fetch, formatDate, scrollToTop} from "../../service/utils";
import toast from "react-hot-toast";
import {format} from "date-fns";
import {BeatLoader} from "react-spinners";
import CurriculumUpdateMOdal from "../../component/modals/CurriculumUpdateModal";
import {useParams} from "react-router-dom";
import DeleteProgModal from "../../component/modals/DeleteProgModal.component";

const isTextarea = (value) => {
  if (value.trim() === "") {
    return {validated: false, message: "Please Enter Today Cover Topics"};
  } else {
    return {validated: true, message: ""};
  }
};

function Curriculum() {
  const {batchId} = useParams();
  const [batch_id, set_batch_id] = useState(batchId);
  useEffect(() => {
    set_batch_id(batchId);
  }, [batchId]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableError, setTableError] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [networkError, setNetworkError] = useState("");

  const [lessonId, setLessonId] = useState("");
  const [topicdata, setTopicdata] = useState("");
  const inputFileRef = useRef(null);
  const [getCurriculum, setGetCurriculum] = useState([]);

  const today = new Date();
  const [topicPdfFile, setTopicPdfFile] = useState(null);
  const [textareaTouch, setTextareaTouch] = useState("");
  const [fileError, setFileError] = useState("");
  const [showDate, setshowDate] = useState("");
  const [prevDate, setPrevDate] = useState(false);

  const {
    hasError: hasErrorTextarea,
    enterValue: enterValueTextarea,
    onChangeHandler: onChangeHandlerTextarea,
    reset: resetTextarea,
    message: TextareaMessage,
    isTouch: isTouchTextarea,
    onBlurHandler: onBlurHandlerTextarea,
  } = useInput(isTextarea, setTextareaTouch);

  const {
    token: {colorBgContainer},
  } = theme.useToken();

  const curDate = formatDate(new Date());

  useEffect(() => {
    setNetworkError("");
    setTextareaTouch("");
    setNetworkError("");
    setFileError("");
  }, [enterValueTextarea, topicPdfFile]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setNetworkError("");
    setSubmitLoading(true);
    if (hasErrorTextarea === true) {
      setSubmitLoading(false);
      setNetworkError("Please Fill All Fields Appropriately!");
      return false;
    } else if (!isTouchTextarea) {
      if (!isTouchTextarea) {
        setTextareaTouch("Please Enter Today Cover Topics!");
      }
      setSubmitLoading(false);
      setNetworkError("Please Fill All Fields Appropriately!");
      return false;
    } else if ((topicPdfFile && fileError) || fileError) {
      setSubmitLoading(false);
      setNetworkError("Please Fill All Fields Appropriately!");
      return false;
    }

    try {
      const token = localStorage.getItem("lgs_token");
      const headers = {Authorization: `Bearer ${token}`};
      const formData = new FormData();
      formData.append("topics", enterValueTextarea);
      formData.append("date", format(today, "yyyy-MM-dd"));
      formData.append("batch_id", batch_id);
      if (topicPdfFile) {
        formData.append("pdf_file", topicPdfFile);
      }
      const response = await fetch(
        `/tutor/add-curriculum-details`,
        "post",
        formData,
        headers
      );
      if (response.data.ok === false) {
        setSubmitLoading(false);
        setNetworkError(response.data.message);
        return false;
      } else {
        setSubmitLoading(false);
        getCurriculumData(curDate);
        toast.success(response.data.message);
        resetTextarea();
        setTopicPdfFile(null);
        setNetworkError("");
        if (inputFileRef.current) {
          inputFileRef.current.value = "";
        }
      }
    } catch (err) {
      setSubmitLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
        toast.error("Something Went Wrong. Please Try Again Later.");
      }
    }
  };

  const getCurriculumData = async (date = curDate) => {
    setshowDate(date);
    setTableError("");
    setTableLoading(true);
    if (date !== curDate) {
      setPrevDate(true);
    } else {
      setPrevDate(false);
    }
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = {Authorization: `Bearer ${token}`};
      const response = await fetch(
        "/tutor/get-curriculum",
        "post",
        {date, batch_id},
        headers
      );
      if (response.data.ok === false) {
        setTableLoading(false);
        setTableError(response.data.message);
      } else {
        setGetCurriculum(response.data.getCurriculum);
        setTableLoading(false);
        setTableError("");
      }
    } catch (err) {
      setTableLoading(false);
      setTableError(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    getCurriculumData(curDate);
  }, [batch_id]);

  const fileChangeHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file type is PDF
      if (file.type === "application/pdf") {
        setTopicPdfFile(e.target.files[0]);
        setFileError("");
      } else {
        setTopicPdfFile(null);
        setFileError("Please select a PDF file.");
      }
    }
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <div
        className="shadow-sm"
        style={{
          padding: 6,
          minHeight: 360,
          background: colorBgContainer,
        }}
      >
        <div className="flex flex-col  ">
          <div className="mx-2" style={{boxShadow: " 2px 2px 18px 2px gray"}}>
            <Calendar getData={getCurriculumData} />
          </div>

          <div className="overflow-x-auto ">
            <div className="inline-block min-w-[100%]  sm:px-6 lg:px-4">
              <div className="overflow-hidden">
                {tableLoading && (
                  <div className="w-[100%] mt-[-200px]  text-center ">
                    <div className="flex justify-center h-screen items-center">
                      <BeatLoader color="#070756" loading={true} size={15} />
                    </div>
                  </div>
                )}
                {/* <div className="flex max-[968px]:flex-col  lg:flex-row"> */}
                <div
                  className={`mt-20 ${
                    !tableLoading && !tableError
                      ? "grid grid-cols-1 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2"
                      : " w-[100%] "
                  }`}
                >
                  <div>
                    {" "}
                    {!prevDate && (
                      <form onSubmit={submitHandler}>
                        {/* flex max-[768px]:flex-col md:flex-row lg:flex-row */}
                        <div className=" ">
                          <div className={`ml-4 w-[98%] max-[768px]:w-[90%]`}>
                            <label className="block text-sm font-bold leading-6 mb-2 text-gray-900">
                              Topic{" "}
                              <span className="text-red-600 font-bold">*</span>
                            </label>
                            <textarea
                              type="text"
                              rows={5}
                              placeholder="Enter Topic Covered"
                              id="topic"
                              name="Topic"
                              className={`block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium  ${
                                hasErrorTextarea || textareaTouch
                                  ? "border-red-600 bg-red-50"
                                  : "focus:border-[#070756]"
                              }`}
                              value={enterValueTextarea}
                              onChange={onChangeHandlerTextarea}
                              onBlur={() => {
                                onBlurHandlerTextarea();
                                setTextareaTouch("");
                              }}
                            />
                            {hasErrorTextarea && (
                              <div className="flex ml-[0.5px]">
                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                  {TextareaMessage}
                                </p>
                              </div>
                            )}
                            {textareaTouch && (
                              <div className="flex ml-[0.5px]">
                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                  {textareaTouch}
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="w-[98%] max-[768px]:w-[90%] mt-6 mx-4 max-[768px]:ml-4  ">
                            <label
                              htmlFor="file label"
                              className="block text-sm font-bold leading-6 text-gray-900"
                            >
                              Select a PDF file
                            </label>
                            <input
                              type="file"
                              label={<>{"Topic Upload"}</>}
                              placeholder="upload file"
                              id="full-name"
                              name="full-name"
                              defaultValue={topicPdfFile}
                              onChange={fileChangeHandler}
                              ref={inputFileRef}
                              className={`block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium  ${
                                fileError
                                  ? "border-red-600 bg-red-50"
                                  : "focus:border-[#070756]"
                              }`}
                            />
                            {topicPdfFile && (
                              <div className="flex items-center mt-2">
                                <i className="fa-regular fa-file-pdf bg-white text-red-500 pl-2 "></i>
                                <span className="ml-2">
                                  {topicPdfFile.name}
                                </span>
                              </div>
                            )}
                            {fileError && (
                              <div className="flex ml-[0.5px]">
                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                  {fileError}
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="w-[90%] ml-4  mt-12 max-[768px]:ml-8 text-center">
                            <button
                              type="submit"
                              //   disabled={isLoading}
                              className={`bg-[#070756] hover:bg-[#00009C] duration-700  w-32 rounded-md  px-2 py-3 text-center text-[16px] font-medium text-white shadow-sm focus:outline-none focus:bg-[#00009C]`}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        <div className="w-full text-center">
                          {submitLoading && (
                            <span className="text-blue-700 text-base text-center">
                              Submitting your lesson log...
                            </span>
                          )}
                          {networkError && (
                            <span className="text-red-700 text-base">
                              {networkError}
                            </span>
                          )}
                        </div>
                      </form>
                    )}
                  </div>
                  <div
                    className={` max-[968px]:w-[100%] ml-4 -mt-2  ${
                      prevDate ? "w-[100%]" : " w-[90%]"
                    }`}
                  >
                    {" "}
                    {!tableLoading && !tableError && (
                      <>
                        <p className="font-semibold mb-3 text-lg">{showDate}</p>
                        <table className=" w-[100%] text-left text-[13px] font-light  ">
                          <thead className="  border-b bg-white font-medium">
                            <tr className="bg-neutral-200">
                              <th scope="col" className=" px-4 py-2">
                                S.No.
                              </th>
                              <th scope="col" className="px-4 py-2 w-[60%]">
                                Topic Covered
                              </th>

                              <th scope="col" className="px-4 py-2">
                                View File
                              </th>
                              <th scope="col" className="px-4 py-2">
                                Action
                              </th>
                              <th scope="col" className="px-4 py-2">
                                Delete
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {getCurriculum.length > 0 &&
                              getCurriculum.map((topic, ind) => {
                                return (
                                  <tr
                                    key={topic.lesson_id}
                                    className="border-b bg-neutral-100 transition duration-300 ease-in-out hover:bg-slate-100 hover:text-black"
                                  >
                                    <td className="whitespace-nowrap px-6 py-2 font-medium">
                                      {ind + 1}
                                    </td>
                                    <td className="px-6 py-2 ">
                                      {topic.topics}
                                    </td>

                                    <td className="whitespace-nowrap px-6 py-2">
                                      {topic.file_name === "null" ||
                                      topic.file_name === "NULL" ||
                                      topic.file_name === "undefined" ||
                                      topic.file_name === "" ||
                                      topic.file_name === null ||
                                      topic.file_name === undefined ? (
                                        <spna className=" px-[7px]">-</spna>
                                      ) : (
                                        <a href={topic.file} target="_blank">
                                          <i className="fa-regular fa-file-pdf text-lg text-red-500 cursor-pointer "></i>
                                        </a>
                                      )}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-2">
                                      <PencilSquareIcon
                                        onClick={() => {
                                          if (!prevDate) {
                                            setModalOpen(true);
                                            setLessonId(topic.lesson_id);
                                            setTopicdata(topic);
                                          }
                                        }}
                                        className={`w-4 h-4 ${
                                          prevDate
                                            ? "text-[#4267b6] cursor-not-allowed"
                                            : "text-[#070756] cursor-pointer"
                                        }  `}
                                      />
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-2">
                                      <TrashIcon
                                        onClick={() => {
                                          if (!prevDate) {
                                            setDeleteId(topic.lesson_id);
                                            setOpenDelete(true);
                                            setTopicdata(topic);
                                          }
                                        }}
                                        className={`w-4 h-4 ${
                                          prevDate
                                            ? "text-red-700 cursor-not-allowed"
                                            : "text-red-700 cursor-pointer"
                                        }  `}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteProgModal
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        id={deleteId}
        url={"/tutor/delete-lesson-log"}
        getData={getCurriculumData}
      />
      <CurriculumUpdateMOdal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        lessonId={lessonId}
        getCurriculumData={getCurriculumData}
        topicdata={topicdata}
        batch_id={batch_id}
      />
    </>
  );
}

export default Curriculum;
