/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToggleSwitch from "../../component/ToggleSwitch.component";
import { fetch } from "../../service/utils";
import { theme } from "antd";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { setallPostedJob } from "../../redux/features/enablerJobSlice";
import ResponseParticipantModal from "../../component/modals/ResponseParticipantModal";
import DeleteProgModal from "../../component/modals/DeleteProgModal.component";

const PostJob = () => {
  // const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  // const [addModalOpen, setAddModalOpen] = useState(false);
  const [participantOpen, setParticipantOpen] = useState(false);
  const [participantInfo, setParticipantInfo] = useState([]);

  const [tableLoading, setTableLoading] = useState(true);
  const [networkError, setNetworkError] = useState("");
  const allCreatedJobs = useSelector(
    (state) => state.allPostedJob.allPostedJob
  );
  const dispatch = useDispatch();

  // *get all programs start
  const getAllCreatedJobs = async () => {
    setNetworkError("");
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch("/enabler/getAll-Job", "get", null, headers);
      if (response.data.ok === false) {
        setTableLoading(false);
        setNetworkError(response.data.message);
      }
      dispatch(setallPostedJob(response.data.getCreatedjob));
      setTableLoading(false);
      setNetworkError("");
    } catch (err) {
      setTableLoading(false);
      setNetworkError(err?.response?.data?.message);
    } finally {
    }
  };
  useEffect(() => {
    getAllCreatedJobs();
  }, []);
  // *get all programs end

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // *create Job Title  start
  const [jobTitle, setJobTitle] = useState("");

  const createJobTitleSubmitHandler = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    setNetworkError("");
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/enabler/create-job",
        "post",
        {
          job_title: jobTitle,
        },
        headers
      );
      if (response && response.data.ok === false) {
        setNetworkError(response.data.message);
        toast.error(response.data.message);
      }
      getAllCreatedJobs();
      toast.success("Job Created Successful!");
      setJobTitle("");
    } catch (err) {
      // setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
        // toast.error("Something Went Wrong. Please Try Again Later.");
      }
    } finally {
    }
  };

  return (
    <>
      <div className="text-center font-bold text-2xl m-4">
        <span className="text-center text-[#070756] ">Created Jobs</span>
      </div>
      {!tableLoading && !networkError && (
        <div className={`mb-[67px]  mt-12 ${allCreatedJobs.length > 0 ? "grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2" : "text-center m-auto w-[80%]"} `}>
          <div className="text-center">
            <div className=" mt-6">
              <div className=" sm:mx-6  max-[640px]:mx-6">
                <form onSubmit={createJobTitleSubmitHandler}>
                  <div className="mt-2">
                    <input
                      id="create-job"
                      name="create-job"
                      type="text"
                      autoComplete="create-job"
                      placeholder="Create a Job Title..."
                      onChange={(e) => setJobTitle(e.target.value)}
                      value={jobTitle}
                      required
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="mt-4 text-center">
                    <button
                      type="submit"
                      className=" bg-[#070756] hover:bg-[#00009C] duration-700 w-32 rounded-md  px-2 py-2 text-center text-sm font-medium text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                    >
                      Create Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>


          <div className="">
            {tableLoading === true && (
              <div className="flex justify-center h-screen items-center">
                <BeatLoader color="#00009C" loading={true} size={15} />
              </div>
            )}

            {networkError && tableLoading === false && (
              <div className="w-[100%] text-center ">
                <span className="text-red-700 text-lg font-semibold">
                  {networkError}
                </span>
              </div>
            )}


            <>
              <div
                className="shadow-sm "
                style={{
                  padding: 6,
                  minHeight: 360,
                  background: colorBgContainer,
                }}
              >
                {allCreatedJobs.length > 0 ? (
                  <div className="flex flex-col mt-4">
                    <div className="overflow-x-auto sm:mx-[1px]  max-[640px]:mx-6 lg:-mx-1">
                      <div className="inline-block w-full  sm:px-6 lg:px-2">
                        <div className="overflow-hidden overflow-x-auto ">
                          <table className="w-full text-left text-[13px] font-light">
                            <thead className="w-full border-b bg-neutral-200  font-medium">
                              <tr>
                                <th scope="col" className=" p-2">
                                  S. NO.
                                </th>
                                <th scope="col" className="p-2">
                                  Job Title
                                </th>
                                <th scope="col" className="p-2">
                                  Response
                                </th>
                                <th scope="col" className="p-2">
                                  Activation Status
                                </th>
                                <th scope="col" className="p-2">
                                  Add Job Details
                                </th>
                                <th scope="col" className="p-2">
                                  Delete
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {allCreatedJobs &&
                                allCreatedJobs.map((enbjob, ind) => {
                                  return (
                                    <tr
                                      key={enbjob.enbjob}
                                      className="border-b bg-neutral-100 transition duration-300 ease-in-out hover:bg-neutral-200 hover:text-black"
                                    >
                                      <td className="whitespace-nowrap px-6 py-2 font-medium">
                                        {ind + 1}
                                      </td>
                                      <td className="whitespace-nowrap px-6 py-2">
                                        {enbjob.job_title}
                                      </td>
                                      <td
                                        className="whitespace-nowrap px-6 py-2 cursor-pointer hover:underline hover:text-red-600 hover:font-extrabold"
                                        onClick={() => {
                                          if (
                                            enbjob.job_response &&
                                            enbjob.job_response.length > 0
                                          ) {
                                            setParticipantOpen(true);
                                            setParticipantInfo(
                                              enbjob.job_response
                                            );
                                          }
                                        }}
                                      >
                                        {enbjob.job_response
                                          ? enbjob.job_response.length
                                          : 0}
                                      </td>
                                      <td className="whitespace-nowrap px-6 py-2">
                                        <ToggleSwitch
                                          id={enbjob.job_id}
                                          changeStatus={enbjob.opening_status}
                                          url={"/enabler/jobstatus/"}
                                          getData={getAllCreatedJobs}
                                          enbData={enbjob}
                                        />
                                      </td>
                                      <td className="whitespace-nowrap px-6 py-2">
                                        <Link
                                          to={`/technohub/enabler/update-job/${enbjob.job_id}`}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6 cursor-pointer"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                            />
                                          </svg>
                                        </Link>
                                      </td>
                                      <td className="whitespace-nowrap px-6 py-2">
                                        <svg
                                          onClick={() => {
                                            setDeleteId(enbjob.job_id);
                                            setOpenDelete(true);
                                          }}
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="red"
                                          className="w-6 h-6 cursor-pointer"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                          />
                                        </svg>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          </div>
        </div>
      )}
      <ResponseParticipantModal
        participantOpen={participantOpen}
        setParticipantOpen={setParticipantOpen}
        participantInfo={participantInfo}
      />
      <DeleteProgModal
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        id={deleteId}
        url={"/enabler/deletejob"}
        getData={getAllCreatedJobs}
      />
    </>
  );
};

export default PostJob;
