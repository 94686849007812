import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import TutorTabs from "./TutorTabs";

const ParentTutorTabs = () => {
  const { batchId } = useParams();
  const [batch_id, set_batch_id] = useState(batchId);
  useEffect(() => {
    set_batch_id(batchId);
  }, [batchId]);

  const batchName = localStorage.getItem("batch_name");

  return (
    <>
      <div className="text-center font-bold text-2xl mt-4 mb-2">
        <span className="text-center text-[#070756]">{batchName}</span>
      </div>
      <TutorTabs />
    </>
  );
};

export default ParentTutorTabs;
