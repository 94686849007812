import React, { useEffect } from "react";
import { scrollToTop } from "../service/utils";

export default function Disclaimer() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-[90%] max-[640px]:p-10 sm:py-16 sm:px-6 md:py-20 lg:py-24">
        <div className="">
          <h1 className=" text-center text-4xl font-bold tracking-tight text-[#424242] sm:text-5xl">
            Disclaimer
          </h1>
          <p className="mt-10 text-[15px] font-normal leading-6 text-gray-600">
            This Website and the information, tools and material contained in it
            (this "Site") are not directed to, or intended for distribution to
            or use by, any person or entity who is a citizen or resident of or
            located in any jurisdiction where such distribution, publication,
            availability or use would be contrary to law or regulation or which
            would subject Lavanya Gurukul its affiliates collectively “Lavanya
            Gurukul " to any registration or licensing requirement within such
            jurisdiction. This Site is subject to periodic update and revision.
            Materials should only be considered current as of the date of
            initial publication appearing thereon, without regard to the date on
            which you may access the information. Lavanya Gurukul maintains the
            right to delete or modify information on this Site without prior
            notice.
          </p>
        </div>
        <div className="mt-6">
          <h1 className=" text-xl font-bold tracking-tight text-[#424242] ">
            Limited License
          </h1>
          <p className="mt-3 text-[15px] font-medium  leading-6 text-gray-600">
            Subject to the terms and conditions set forth in this Agreement,
            Lavanya Gurukul will grant a non-exclusive, non-transferable,
            limited right to access this site and the materials thereon.
          </p>
          <p className="my-3 text-[14px] font-medium  leading-6 text-gray-600">
            You hereby agree and confirm that:
          </p>
          <ul className="px-6 ">
            <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
              Access to this site and the information contained here in is not
              unlawful under the applicable laws of the jurisdiction where
              Lavanya Gurukul resident and from where Lavanya Gurukul accessing
              this site.
            </li>
            <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
              Access to information on the site www.lavanyagurukul.com does not
              in any manner constitute an offer to sell or a solicitation of any
              offer to buy any of the securities of Passion sports.
            </li>
            <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
              I shall not circulate copies of this information in any manner
              (including but not restricted to photo copying and email) of the
              information and data on this site.
            </li>
            <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
              I agree not to reproduce, retransmit, distribute, disseminate,
              sell, publish, broadcast or circulate the contents to anyone.
            </li>
          </ul>

          <h1 className=" mt-5 text-xl font-bold tracking-tight text-[#424242] ">
            You agree not to:
          </h1>

          <ul className="px-6 mt-3">
            <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
              Interrupt or attempt to interrupt the operation of the site in any
              way.
            </li>
            <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
              Intrude or attempt to intrude into the site in any way.
            </li>
            <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
              Post any obscene, defamatory or annoying materials on the site.
            </li>
            <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
              Obscure any materials, including this notice, already posted on
              the site.
            </li>
            <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
              Use the site or any contents thereof to defame, intimidate, annoy
              or otherwise cause nuisance or breach the rights of any person.
            </li>
          </ul>

          <p className="my-5 text-[15px] font-medium  leading-6 text-gray-600">
            Lavanya Gurukul authorizes to view and download the information
            ("Materials") at this Web site ("Site") only for personal,
            non-commercial use. This authorization is not a transfer of title in
            the Materials and copies of the Materials and is subject to the
            following restrictions:
          </p>
          <ul className="px-6 ">
            <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
              Retain, on all copies of the Materials downloaded, all copyright,
              trademarks and other consent.
            </li>
            <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
              Not modify the Materials in any way nor reproduce or display,
              perform, or distribute or otherwise use them for any public or
              commercial purpose.
            </li>
            <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
              Not transfer the Materials to any other person unless you give
              them notice of, and they agree to accept, the obligations arising
              under these terms and conditions of use.
            </li>
          </ul>

          <p className="mt-4 text-[15px] font-medium  leading-6 text-gray-600">
            You agree to abide by all additional restrictions displayed on the
            Site as it may be updated from time to time. This Site, including
            all Materials, is copyrighted and protected by worldwide copyright
            laws and treaty provisions. You agree to comply with all copyright
            laws worldwide in your use of this Site and to prevent any
            unauthorized copying of the Materials. Except as expressly provided
            herein, Lavanya Gurukul does not grant any express or implied right
            to you under any patents, trademarks, copyrights or trade secret
            information.
          </p>
          <p className="mt-4 text-[15px] font-medium  leading-6 text-gray-600">
            The information, material or services included in or available
            through this site may include inaccuracies or typographical errors.
            Changes are periodically made to the site/services and to the
            information therein. Lavanya Gurukul may make improvements and/or
            changes in the site/services at any time. Advice received via this
            site should not be relied upon for personal, medical, legal or
            financial decisions and you should consult an appropriate
            professional for specific advice tailored to your situation.
          </p>
          <p className="mt-4 text-[15px] font-medium  leading-6 text-gray-600">
            You specifically agree that Lavanya Gurukul shall not be responsible
            for unauthorized access to or alteration of your transmissions or
            data, any material or data sent or received or not sent or received,
            or any transactions entered into through this site. You specifically
            agree that Lavanya Gurukul Center is not responsible or liable for
            any threatening, defamatory, obscene, offensive or illegal content
            or conduct of any other party or any infringement of another's
            rights, including intellectual property rights. You specifically
            agree that Lavanya Gurukul Center not responsible for any content
            sent using and/or included in this site by any third party.
          </p>
          <p className="mt-4 text-[15px] font-medium  leading-6 text-gray-600">
            In no event shall Lavanya Gurukul will be liable for any direct,
            indirect, punitive, incidental, special, consequential damages or
            any damages what so ever including, without limitation, damages for
            loss of use, data or profits, arising out of or in any way connected
            with the use or performance of this site/service, with the delay or
            inability to use this site/service or related services, the
            provision of or failure to provide services, or for any information,
            products, services and material obtained through this site, or
            otherwise arising out of the use of this site/service, whether based
            on contract, tort, negligence, strict liability or otherwise, even
            if Lavanya Gurukul Center or any of its suppliers has been advised
            of the possibility of damages. If you are dissatisfied with any
            portion of this site/service, or with any of these terms of use,
            your sole and exclusive remedy is to discontinue using this
            site/service. The foregoing are subject to the laws of the Republic
            of India and the courts in Bhopal, India shall have the exclusive
            jurisdiction on any dispute that may arise out of the use of this
            site. Please proceed only if you accept all the conditions
            enumerated herein above, out of your free will and consent.
          </p>
        </div>
      </div>
    </div>
  );
}
