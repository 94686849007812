import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import LearnerLoginMiddleware from "../middleware/learner-login-middleware";
import LearnerTokenMiddleware from "../middleware/learner-token-middleware";
import SelectCourseMiddleware from "../middleware/select-course-middleware";
import ProfileMiddleware from "../middleware/profile-middleware";
import SponsorshipForm from "../pages/learner/SponsorshipForm";
import AllPrograms from "../pages/learner/AllPrograms";
import { useState, useEffect } from "react";
import { fetch } from "../service/utils";
import { setAllottedPrograms } from "../redux/features/myProgramsSlice";
import { useDispatch, useSelector } from "react-redux";
import ErrorPage from "../pages/ErrorPage";
import LearnerSignin from "../component/learner/LearnerSignin";
import LearnerSignup from "../component/learner/LearnerSignup";
import MyBatch from "../pages/learner/MyBatch";

const LearnerProgram = lazy(() => import("../pages/learner/DesiredPrograms"));
const ChangePwd = lazy(() => import("../pages/learner/ChangePwd"));

const LearnerProfile = lazy(() => import("../pages/learner/LearnerProfile"));
const LoginAndSignUpComponent = lazy(() =>
  import("../pages/learner/LoginAndSignUpComponent")
);
const LearnerCheckout = lazy(() => import("../pages/learner/LearnerCheckout"));

const MyPrograms = lazy(() => import("../pages/learner/MyPrograms"));

const Learner = () => {
  const [loading, setLoading] = useState(true);
  const [networkError, setNetworkError] = useState("");

  const dispatch = useDispatch();

  // *get my-programs start
  const getAllottedProgram = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      if (!user_id) {
        return false;
      }
      const res = await fetch(
        "/learner/allotted-program",
        "post",
        { user_id },
        headers
      );
      setLoading(false);
      if (res.data.ok === false) {
        setLoading(false);
        setNetworkError(res.data.message);
      }
      dispatch(setAllottedPrograms(res.data.allotted_program));
      setNetworkError("");
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    }
  };
  const user_id = useSelector((state) => state.user.userData.user_id);
  useEffect(() => {
    getAllottedProgram(user_id);
  }, [user_id]);
  // *get my-programs end

  return (
    <Routes>
      <Route
        path="allprogram"
        element={
          <SelectCourseMiddleware>
            <LearnerProgram />
          </SelectCourseMiddleware>
        }
      />
      <Route
        path="all-programs"
        element={
          <LearnerTokenMiddleware>
            <AllPrograms />
          </LearnerTokenMiddleware>
        }
      />
      <Route
        path="my-programs"
        element={
          <LearnerTokenMiddleware>
            <MyPrograms networkError={networkError} loading={loading} />
          </LearnerTokenMiddleware>
        }
      />
      <Route
        path="my-batch"
        element={
          <LearnerTokenMiddleware>
            <MyBatch networkError={networkError} loading={loading} />
          </LearnerTokenMiddleware>
        }
      />

      <Route
        path="changepwd"
        element={
          <LearnerTokenMiddleware>
            <ChangePwd />
          </LearnerTokenMiddleware>
        }
      />

      <Route
        path="apply"
        element={
          <LearnerTokenMiddleware>
            <SponsorshipForm />
          </LearnerTokenMiddleware>
        }
      />

      <Route
        path="profile"
        element={
          <ProfileMiddleware>
            <LearnerProfile />
          </ProfileMiddleware>
        }
      />
      <Route
        path="checkout"
        element={
          <LearnerTokenMiddleware>
            <LearnerCheckout />
          </LearnerTokenMiddleware>
        }
      />

      {/* <Route
        path="learner-auth"
        element={
          <LearnerLoginMiddleware>
            <LoginAndSignUpComponent />
          </LearnerLoginMiddleware>
        }
      /> */}
      <Route
        path="signup"
        element={
          <LearnerLoginMiddleware>
            <LearnerSignup />
          </LearnerLoginMiddleware>
        }
      />
      <Route
        path="signin"
        element={
          <LearnerLoginMiddleware>
            <LearnerSignin />
          </LearnerLoginMiddleware>
        }
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Learner;
