import React, { useEffect } from "react";
import { scrollToTop } from "../service/utils";
import aboutImg from "../img/about2.avif"

export default function About() {
    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <div className="bg-white">
            <div className="about-container bg-no-repeat bg-cover bg-center h-[400px]" style={{ backgroundImage: `url(${aboutImg})` }}>
                <div className="about-content p-8">
                    <h1 className="text-5xl font-bold mt-32 text-white text-center">About Us</h1>
                    {/* <p className="text-lg">
                        jhj
                    </p> */}
                </div>
            </div>
            <div className="mx-auto max-w-[90%] max-[640px]:p-10 sm:py-16 sm:px-6 md:py-20 lg:py-24">
                <div className="">
                    <h1 className=" text-start text-4xl font-bold tracking-tight text-[#424242] ">
                        Vision
                    </h1>
                    <p className="mt-4 text-[15px] font-normal leading-6 text-gray-600">
                        Lavanya Gurukul is dedicated to fostering an environment where gratitude is expressed through the pursuit of knowledge and empowerment in education, sports, and technology. We believe in a holistic approach where effort is the true measure of intent, and personal growth is achieved by embracing challenges and learning from every situation.
                    </p>
                </div>

                <div className="mt-16">
                    <h1 className=" mb-4 text-4xl font-bold tracking-tight text-[#424242] ">
                        Mission
                    </h1>


                    <ul className="px-6 ">
                        <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
                            Revisit Learning: We aim to revolutionize the learning process by focusing on situation-based and challenge-based learning methods that encourage active engagement and practical application of knowledge.
                        </li>
                        <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
                            Redefine Winning: Our mission is to redefine the concept of winning, emphasizing personal growth, teamwork, and the joy of participation over traditional competitive metrics.
                        </li>
                        <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
                            Balance the Physical & Spiritual Self: We advocate for a balanced approach to well-being that nurtures both the physical and spiritual aspects of the individual, promoting overall health and inner peace.
                        </li>
                        <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
                            Harmonious Technology: We strive to integrate technology seamlessly into our lives in a way that enhances our human experience, fosters connections, and supports our mission and values.
                        </li>
                    </ul>

                </div>

                <div className="mt-16">
                    <h1 className="mb-4 text-4xl font-bold tracking-tight text-[#424242] ">
                        Values
                    </h1>


                    <ul className="px-6 ">
                        <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
                            Transparency in Practices: We are committed to maintaining openness and honesty in all our interactions and operations, ensuring trust and integrity are at the core of everything we do.
                        </li>
                        <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
                            Collaborating & Cross-Leveraging: We believe in the power of collaboration and actively seek opportunities to work together and leverage our collective strengths for greater impact.
                        </li>
                        <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
                            Being a Better Version Each Day: Personal growth is a continuous journey, and we are dedicated to the pursuit of self-improvement and becoming better versions of ourselves with each passing day.
                        </li>
                        <li className="text-[14px] font-medium  leading-6 text-gray-600 list-disc">
                            Purpose Precedes Preferences: We prioritize our mission and collective goals above individual preferences, ensuring that our actions are always aligned with our larger purpose.
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    );
}
