import React from "react";
import { Route, Routes } from "react-router-dom";

import TutorSideBar from "../../component/tutor/TutorSideBar";
import TutorHome from "./TutorHome";
import ParentTutorTabs from "../../component/tutor/ParentTutorTabs";

import Curriculum from "./Curriculum";

const TutorDashboard = ({ dashError }) => {
  return (
    <>
      <div className=" float-left clear-both h-screen sticky top-0 ">
        <TutorSideBar />
      </div>
      <div className="">
        <Routes>
          <Route path="home" element={<TutorHome dashError={dashError} />} />
        </Routes>
      </div>
      <div className="">
        <Routes>
          <Route path="batches/:batchId" element={<ParentTutorTabs />} />
        </Routes>
      </div>
      <div className="">
        <Routes>
          <Route path="curriculum" element={<Curriculum />} />
        </Routes>
      </div>
    </>
  );
};

export default TutorDashboard;
