import React, { useState, useEffect, useCallback } from "react";
import { fetch } from "../../service/utils";
import { theme } from "antd";
import { BeatLoader } from "react-spinners";
import useRazorpay from "react-razorpay";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import images from "../../img/Technohub.jpeg";
import { RAZORPAY_TEST_KEY } from "../../service/constants";

const EnablerSponsor = () => {
  const Razorpay = useRazorpay();
  const [isLoading, setIsLoading] = useState(true);
  const [networkError, setNetworkError] = useState("");
  const [applyLoading, setApplyLoading] = useState(false);

  const [allSponsors, setAllSponsors] = useState([]);

  const navigate = useNavigate();
  const userdata = useSelector((state) => state.user.userData);

  const getAllSponsors = async () => {
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/learner/get-sponsor",
        "get",
        null,
        headers
      );
      setIsLoading(false);
      if (response.ok === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
      }
      setAllSponsors(response.data.getSponsors);
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    } finally {
    }
  };
  useEffect(() => {
    getAllSponsors();
  }, []);

  const [checkState, setCheckState] = useState([]);
  const [selectLeaners, setSelectLeaners] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setCheckState((prevCheckState) => [...prevCheckState, Number(value)]);
    } else {
      setCheckState((prevCheckState) =>
        prevCheckState.filter((item) => item != value)
      );
    }
  };

  const selectedSponsor = selectLeaners.filter((learner) =>
    checkState.includes(learner.learner_id)
  );
  const handleSponsordata = (learnerData) => {
    if (
      checkState &&
      !selectLeaners.some(
        (learner) => learner.learner_id === learnerData.learner_id
      )
    ) {
      setSelectLeaners((prevCheckState) => [...prevCheckState, learnerData]);
    }
  };
  // *checkbox end

  const getTotalFee = () => {
    let fee = 0;
    allSponsors.map((benef) => {
      let selectedBenef = checkState.find((id) => id === benef.user_id);
      if (selectedBenef) {
        fee += benef.program_fee;
      }
    });
    return fee;
  };

  const sponsorHandler = async () => {
    setApplyLoading(true);
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const res = await fetch(
        "/enabler/pay-sponsor",
        "post",
        {
          pay_amount: getTotalFee(),
          learners: selectedSponsor,
        },
        headers
      );
      if (res?.data?.ok === true) {
        setApplyLoading(false);

        displayRazorpay(res.data.order, res.data.pay_id, selectedSponsor);
      }
      if (res.data.ok === false) {
        setApplyLoading(false);
        setNetworkError(res.data.message);
      }
    } catch (err) {
      setApplyLoading(false);
    }
  };
  const displayRazorpay = useCallback((order, pay_id, selectedSponsor) => {
    try {
      const options = {
        key: RAZORPAY_TEST_KEY, // Enter the Key ID generated from the Dashboard
        // amount: getTotalFee() * 100,
        amount: order.amount,
        currency: order.currency,
        name: "Lavanya Gurukul",
        description: "Test Transaction",
        image: `${images}`,
        order_id: order.id,
        handler: async (response) => {
          const formData = new FormData();
          formData.set("razorpayPaymentId", response.razorpay_payment_id);
          formData.set("razorpayOrderId", response.razorpay_order_id);
          formData.set("razorpaySignature", response.razorpay_signature);

          const token = localStorage.getItem("lgs_token");
          const headers = { Authorization: `Bearer ${token}` };

          try {
            const res = await fetch(
              "/enabler/sponsor-payment-handler",
              "post",
              {
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
                pay_id: pay_id,
                learners: selectedSponsor,
              },
              headers
            );
            getAllSponsors();
            setSelectLeaners([]);
          } catch (err) {
            console.log(err);
          }
          navigate("/technohub/enabler/payment-status");
          //  toast.success("Successfully Purchased Program!");
        },
        prefill: {
          name: userdata.contact_person_name,
          email: userdata.contact_person_email,
          contact: userdata.contact_person_mobile,
        },
        notes: {
          address: "Lavanya Gurukul",
        },
        theme: {
          color: "#021c63",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // const totalamountToPay = selectBox.reduce((ttl, amount) => ttl + amount, 0);

  // Pageination start here
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allSponsors && allSponsors.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(allSponsors && allSponsors.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <>
      <div className="text-center font-bold text-2xl m-6">
        <span className="text-center text-[#070756]">
          Sponsorship Applicants
        </span>
      </div>
      <div
        style={{
          padding: 6,
          minHeight: 360,
          background: colorBgContainer,
        }}
      >
        {isLoading && (
          <div className="flex justify-center h-screen items-center">
            <BeatLoader color="#070756" loading={true} size={15} />
          </div>
        )}

        {networkError && isLoading === false && (
          <div className="text-center mt-8 mb-[343px]">
            <strong className="font-bold text-red-600">{networkError}</strong>
          </div>
        )}

        {isLoading && networkError && (
          <>
            <div className=" flex flex-col mx-4  border-1 text-center max-[768px]:overflow-x-auto">
              <div className="sm:-mx-4 lg:-mx-2">
                <div className="inline-block min-w-[100%]  sm:px-6 lg:px-4">
                  <div className="overflow-hidden ">
                    <table className=" max-[768px]:mx-2 w-[100%] text-left text-sm font-light ">
                      <thead className="  border-b  font-medium bg-neutral-200">
                        <tr className="py-4 w-[100%]">
                          <th scope="col" className=" px-2 py-2 w-[3%]">
                            S. No.
                          </th>
                          <th scope="col" className="px-2 py-2 w-[12%]">
                            Name
                          </th>
                          <th scope="col" className="px-2 py-2 w-[9%]">
                            Contact
                          </th>{" "}
                          <th scope="col" className="px-2 py-2 w-[12%]">
                            Father's occupation
                          </th>
                          <th scope="col" className="px-2 py-2 w-[12%]">
                            Family Income
                          </th>
                          <th scope="col" className="px-2 py-2 w-[22%]">
                            Address
                          </th>
                          <th scope="col" className="px-2 py-2 w-[12%]">
                            Program Name
                          </th>
                          <th scope="col" className="px-2 py-2 w-[12%]">
                            Program Fee
                          </th>
                          <th scope="col" className="px-2 py-2 w-[5%]">
                            Select
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allSponsors &&
                          currentItems.map((sponsor, ind) => {
                            let learnerData = "";
                            return (
                              <tr
                                key={sponsor}
                                className="border-b bg-neutral-100 transition duration-300 ease-in-out hover:bg-neutral-200 hover:text-black"
                              >
                                <td className="whitespace-nowrap px-2 py-4 font-medium">
                                  {ind + 1}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4">
                                  {sponsor.learner_name}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4">
                                  {sponsor.learner_contact}
                                </td>
                                <td className=" px-2 py-4">
                                  {sponsor.father_occupation}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4">
                                  {sponsor.family_income}
                                </td>
                                <td className="px-2 py-4">
                                  {sponsor?.learner_address?.user_address},{sponsor?.learner_address?.user_city}, {sponsor?.learner_address?.user_state}, {sponsor?.learner_address?.user_pincode}
                                </td>
                                <td className=" px-2 py-4">
                                  {sponsor.program_name}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4 font-medium">
                                  &#8377; {sponsor.program_fee}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4">
                                  <input
                                    type="checkbox"
                                    className="w-5 h-5"
                                    value={sponsor.user_id}
                                    onChange={handleCheckboxChange}
                                    onClick={() =>
                                      handleSponsordata(
                                        (learnerData = {
                                          program_fee: sponsor.program_fee,
                                          program_id: sponsor.program_id,
                                          learner_id: sponsor.user_id,
                                          enabler_id: userdata.user_id,
                                        })
                                      )
                                    }
                                    checked={checkState.includes(
                                      Number(sponsor.user_id)
                                    )}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {checkState.length > 0 === true && (
                <h2 className="mt-4 font-bold text-[20px] ">
                  Total Fee: &#8377; {getTotalFee()}
                </h2>
              )}
            </div>
            <div className="px-5 py-5 border-t flex   flex-row max-[640px]:flex-col bg-white">
              <div className="flex-initial w-[70%] sm:w-[65%] md:w-[80%] lg:w-[90%] text-left">
                <span className="text-xs xs:text-sm text-gray-900 ">
                  {/* Showing 1 to 10 of */}
                  {allSponsors && (
                    <>
                      Total {currentItems.length} Entries

                    </>
                  )}
                </span>
              </div>
              <div className="inline-flex ">
                <button
                  className="text-sm bg-slate-200  hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-l"
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                {pageNumbers.map((number) => (
                  <button
                    key={number}
                    onClick={() => paginate(number)}
                    className={`text-sm bg-slate-200  hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 ${currentPage === number
                      ? "bg-blue-500 text-black border-[1px] border-gray-400"
                      : "text-gray-800"
                      }`}
                  >
                    {number}
                  </button>
                ))}
                <button
                  className="text-sm bg-slate-200  hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-r"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === pageNumbers.length}
                >
                  Next
                </button>
              </div>
            </div>
            <div className="mx-4 mb-[203px] mt-6 flex flex-col items-center justify-center  text-center ">
              <button
                onClick={() => sponsorHandler()}
                disabled={checkState.length == 0}
                className={`${applyLoading
                  ? "cursor-not-allowed bg-green-300 w-48 max-[640px]:w-24 sm:w-40 md:w-48 lg:w-52 rounded-md px-2 py-[10px] text-center   text-white max-[640px]:text-lg text-xl font-medium"
                  : `w-48 max-[640px]:w-24 sm:w-40 md:w-48 lg:w-52 rounded-md px-2 py-[10px] text-center   text-white max-[640px]:text-lg text-xl font-medium ${checkState.length == 0
                    ? "cursor-not-allowed bg-[#070756]"
                    : "hover:bg-[#00009C] bg-[#070756]"
                  }`
                  }`}
              >
                {applyLoading ? (
                  <div className="flex justify-center items-center">
                    <BeatLoader color="#042009" loading={true} size={12} />
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <div className="w-8 h-8 mx-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64 64"
                        stroke-width="3"
                        stroke="currentColor"
                        fill="none"
                      >
                        <path
                          d="M15.08,52.89,8.27,42.64a1.1,1.1,0,0,1,.32-1.53l4.29-2.79a1.11,1.11,0,0,1,1.51.3l7,10.06a1.1,1.1,0,0,1-.29,1.54l-4.44,3A1.1,1.1,0,0,1,15.08,52.89Z"
                          stroke-linecap="round"
                        />
                        <path
                          d="M15.16,39.69l7.17-4.51a2,2,0,0,1,1.56-.24c2.54.65,10.2,2.76,13,3.74,2.13.75,3,2.84,2.13,4.23-1.16,1.86-2.92,1.65-4.19,1.3a4.29,4.29,0,0,1-.5-.19l-5-2.13"
                          stroke-linecap="round"
                        />
                        <path
                          d="M20.72,47.82l2.34-1.34,13.26,3.63a1,1,0,0,0,.81-.13C39,48.78,47,43.49,50.94,40.7A2.2,2.2,0,0,0,51.22,37a2.86,2.86,0,0,0-3.41-.3c-1.39.77-5.22,3.62-5.22,3.62"
                          stroke-linecap="round"
                        />
                        <circle
                          cx="38.09"
                          cy="20.44"
                          r="13"
                          stroke-linecap="round"
                        />
                        <path
                          d="M33.81,13.85h2.08c2,0,4.26.67,4.26,3.76,0,3.35-2.84,4.63-5.85,4.29a.17.17,0,0,0-.14.29l6.4,6.89"
                          stroke-linecap="round"
                        />
                        <line
                          x1="42.6"
                          y1="13.85"
                          x2="35.43"
                          y2="13.85"
                          stroke-linecap="round"
                        />
                        <line
                          x1="33.92"
                          y1="17.72"
                          x2="42.65"
                          y2="17.72"
                          stroke-linecap="round"
                        />
                      </svg>{" "}
                    </div>
                    Sponsor
                  </div>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EnablerSponsor;
