import React, { useState, useEffect, useRef } from "react";
import { emailreg } from "../../validations/validation";
import { Link, useNavigate } from "react-router-dom";
import { useInput } from "../../hook/input-hook";
import { fetch } from "../../service/utils";
import { setUser } from "../../redux/features/userSlice";
import { useDispatch } from "react-redux";
import images from "../../service/imageImporter";
import toast from "react-hot-toast";
import EyeIcon from "../../component/eye-icon/EyeIcon";
import ForgotPwd from "../../component/modals/ForgotPwd";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import Input from "../../component/input/Input.component";

const isEmail = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Your Email" };
  } else if (!emailreg.test(value)) {
    return { validated: false, message: "Your Email Is Invalid" };
  } else {
    return { validated: true, message: "" };
  }
};
const isPassword = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Your Password" };
  } else {
    return { validated: true, message: "" };
  }
};

function LoginAdmin(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [openForgotPwd, setOpenForgotPwd] = useState(false);
  const [isEmailTouchblur, setEmailTouchblur] = useState("");
  const [isPasswordTouch, setisPasswordTouch] = useState("");

  const {
    hasError: hasErrorEmail,
    enterValue: enterValueEmail,
    onChangeHandler: onChangeHandlerEmail,
    reset: resetEmail,
    message: emailMessage,
    isTouch: isTouchEmail,
    onBlurHandler: onBlurHandlerEmail,
  } = useInput(isEmail, setNetworkError, setEmailTouchblur);

  const {
    hasError: hasErrorPassword,
    enterValue: enterValuePassword,
    message: passwordMessage,
    onChangeHandler: onChangeHandlerPassword,
    reset: resetPassword,
    isTouch: isTouchPassword,
    onBlurHandler: onBlurHandlerPwd,
  } = useInput(isPassword, setNetworkError, setisPasswordTouch);

  const [subError, setSubError] = useState("");
  const dispatch = useDispatch();

  const emailRef = useRef(null);
  const pwdRef = useRef(null);


  useEffect(() => {
    setSubError("");
    setEmailTouchblur("");
    setisPasswordTouch("");
  }, [enterValueEmail, enterValuePassword]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setNetworkError("");

    if (hasErrorPassword === true || hasErrorEmail === true) {
      setSubError("fields");
      setIsLoading(false);
      return false;
    } else if (!isTouchEmail || !isTouchPassword) {
      if (!isTouchEmail) {
        setEmailTouchblur("Please Enter Your Email");
      }
      if (!isTouchPassword) {
        setisPasswordTouch("Please Enter Your Password");
      }
      setSubError("fields");
      setIsLoading(false);
      return false;
    }

    try {
      const response = await fetch("/auth/admin-login", "post", {
        email: enterValueEmail,
        password: enterValuePassword,
      });
      setIsLoading(false);
      if (response.data) {
        const userData = {
          user_id: response.data.user.user_id,
          role_id: response.data.user.role_id,
          user_name: response.data.user.user_name,
          user_email: response.data.user.user_email,
          user_mobile_no: response.data.user.user_mobile_no,
          user_address: response.data.user.user_address,
        };
        dispatch(setUser(userData));
        localStorage.setItem("lgs_token", response.data.token);
        resetEmail();
        resetPassword();
        navigate("/admin/dashboard/home");
        toast.success("Login Successful!");
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
        toast.error("Something Went Wrong. Please Try Again Later.");
      }
    } finally {
    }
  };
  const [type, setType] = useState("password");

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  const role_id = "1";

  const onKeyDownHandlerPwd = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitHandler(e);
    }
  }

  const onKeyDownHandlerEmail = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }


  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-[67.5px] lg:px-8 bg-white">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src={images[`Technohub.jpeg`]}
            alt="Your Company"
          />
          <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-[#070756]">
            Sign in to Admin Account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={submitHandler}>
            <Input
              type="text"
              label="Email"
              placeholder="Enter Your Email"
              id="email"
              name="email"
              ref={emailRef}
              enterValue={enterValueEmail}
              onChangeHandler={onChangeHandlerEmail}
              onKeyDownHandler={onKeyDownHandlerEmail}
              hasError={hasErrorEmail}
              errorMessage={emailMessage}
              isTouch={isEmailTouchblur}
              setIsTouch={setEmailTouchblur}
              onBlurHandler={onBlurHandlerEmail}
            />

            <Input
              type={type}
              label="Password"
              placeholder="Enter Your Password"
              id="password"
              name="password"
              ref={pwdRef}
              enterValue={enterValuePassword}
              onChangeHandler={onChangeHandlerPassword}
              onKeyDownHandler={onKeyDownHandlerPwd}
              hasError={hasErrorPassword}
              errorMessage={passwordMessage}
              isTouch={isPasswordTouch}
              setIsTouch={setisPasswordTouch}
              onBlurHandler={onBlurHandlerPwd}
              handleToggle={handleToggle}
              handleToggleParam="password"
            />

            <div className="grid grid-cols-2 max-[640px]:grid-cols-1">
              <div className="mx-4 max-[640px]:my-4">
                <button
                  onClick={() => navigate("/")}
                  className="flex w-full justify-center rounded-md bg-red-500  px-2 py-2 text-[16px]  font-medium leading-6 text-white shadow-sm duration-700 hover:bg-[#070756]  focus:outline-none focus:bg-[#070756]"
                >
                  Back Home
                </button>
              </div>
              <div className="mx-4">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`bg-[#070756] hover:bg-[#00009C] duration-700  w-full rounded-md  px-2 py-2 text-center text-[16px] font-medium text-white shadow-sm focus:outline-none focus:bg-[#00009C] ${isLoading ? "cursor-not-allowed" : ""
                    }`}
                >
                  {isLoading ? "Loading..." : "Sign In"}
                </button>
              </div>
            </div>
          </form>
          <div className="text-red-500 text-xs leading-16  text-center pt-5">
            {subError === "fields" && (
              <strong className="text-red-500 text-xs leading-16 text-center">
                Please Fill All TheFields Appropriately.
              </strong>
            )}
            {networkError && (
              <strong className="text-red-500 text-xs leading-16  text-center">
                {networkError}
              </strong>
            )}
            {isLoading && (
              <strong className="text-[#00009C] text-xs leading-16 text-center">
                Please Wait, Signing In...
              </strong>
            )}
          </div>
          <div className="flex items-center justify-between mt-6">
            {" "}
            <div className="text-sm leading-16">
              <Link
                href="#"
                className="font-semibold text-[#070756] hover:text-[#00009C]"
                onClick={() => {
                  setOpenForgotPwd(true);
                }}
              >
                Forgot password?
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ForgotPwd
        openForgotPwd={openForgotPwd}
        setOpenForgotPwd={setOpenForgotPwd}
        role_id={role_id}
      />
    </>
  );
}

export default LoginAdmin;
