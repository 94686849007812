import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { fetch, formatDate } from "../../service/utils";
import { useInput } from "../../hook/input-hook";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const isTextarea = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Today Cover Topics" };
  } else {
    return { validated: true, message: "" };
  }
};

export default function CurriculumUpdateMOdal({
  modalOpen,
  setModalOpen,
  lessonId,
  topicdata,
  getCurriculumData,
  batch_id,
}) {
  const cancelButtonRef = useRef(null);

  const curDate = formatDate(new Date());

  const [responseErr, setResponseErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [topicPdfFile, setTopicPdfFile] = useState("");
  const [textareaTouch, setTextareaTouch] = useState("");

  const {
    hasError: hasErrorTextarea,
    enterValue: enterValueTextarea,
    onChangeHandler: onChangeHandlerTextarea,
    reset: resetTextarea,
    message: TextareaMessage,
    isTouch: isTouchTextarea,
    setDirect: setDirectTextarea,
    onBlurHandler: onBlurHandlerTextarea,
  } = useInput(isTextarea, setTextareaTouch);

  useEffect(() => {
    setDirectTextarea(topicdata?.topics);
  }, [modalOpen]);

  const updateCurriculum = async () => {
    try {
      setResponseErr("");
      setIsLoading(true);
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const formData = new FormData();
      formData.append("topics", enterValueTextarea);
      formData.append("lesson_id", lessonId);
      formData.append("batch_id", batch_id);
      if (topicPdfFile) {
        formData.append("pdf_file", topicPdfFile);
      }
      const response = await fetch(
        `/tutor/curriculum-update`,
        "put",
        formData,
        headers
      );
      setIsLoading(false);
      if (response.ok === false) {
        setIsLoading(false);
        setResponseErr(response.data.message);
      }
      setResponseErr("");
      setModalOpen(false);
      getCurriculumData(curDate);
      toast.success(response.data.message);
      resetTextarea();
      setTopicPdfFile("");
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setResponseErr(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setResponseErr("Something Went Wrong. Please Try Again Later.");
        toast.error("Something Went Wrong. Please Try Again Later.");
      }
    }
  };

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setModalOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="max-[400px]:w-[80%] max-[640px]:w-[80%] sm:w-[80%] md:w-[80%] lg:w-[60%] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ">
                <div className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Link to="#">
                    <button
                      type="button"
                      className="mt-2 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-[#070756] sm:mt-0 sm:w-auto max-[640px]:w-[20%] max-[460px]:w-[30%] max-[270px]:w-[40%]"
                      onClick={() => {
                        setModalOpen(false);
                        // resetfield();
                      }}
                      ref={cancelButtonRef}
                    >
                      <XMarkIcon className="w-5 h-5" />
                    </button>
                  </Link>
                </div>
                {/* <div className="bg-white  px-4 pb-4 pt-5 sm:p-6 sm:pb-4 font-semibold text-center"> */}
                <div className="bg-white  px-4 font-semibold text-center mb-6">
                  <h2>Update Curriculum Details </h2>
                </div>
                <div className="ml-[10%] mb-6">
                  <div className="grid grid-cols-1 ">
                    <div className="w-4/5 mt-3">
                      <label className="block text-sm font-bold leading-6 mb-2 text-gray-900">
                        Topic <span className="text-red-600 font-bold">*</span>
                      </label>
                      <textarea
                        type="text"
                        rows={5}
                        placeholder="Enter Cover Topic Today"
                        id="topic"
                        name="Topic"
                        className={`block w-full rounded-md border border-gray-300 py-3 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium `}
                        defaultValue={enterValueTextarea}
                        onChange={onChangeHandlerTextarea}
                        onBlur={() => {
                          onBlurHandlerTextarea();
                          setTextareaTouch("");
                        }}
                      />
                      {hasErrorTextarea && (
                        <div className="flex ml-[0.5px]">
                          <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                          <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                            {TextareaMessage}
                          </p>
                        </div>
                      )}
                      {textareaTouch && (
                        <div className="flex ml-[0.5px]">
                          <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                          <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                            {textareaTouch}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 ">
                    <div className="w-4/5 mt-6">
                      <label className="block text-sm font-bold leading-6 mb-2 text-gray-900">
                        Upload File{" "}
                        <span className="text-red-600 font-bold">*</span>
                      </label>
                      <input
                        type="file"
                        label={<>{"Topic Upload"}</>}
                        placeholder="upload file"
                        id="full-name"
                        name="full-name"
                        defaultValue={topicPdfFile}
                        onChange={(e) => setTopicPdfFile(e.target.files[0])}
                        className={`block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium `}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  {isLoading && (
                    <strong className="text-blue-800 text-sm  text-center">
                      {isLoading}
                    </strong>
                  )}
                  {responseErr && (
                    <strong className="text-red-700 text-sm  text-center">
                      {responseErr}
                    </strong>
                  )}
                </div>
                <div className="bg-gray-50 px-4 py-3 mb-4 mt-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    // disabled={loading}
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      updateCurriculum();
                    }}
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
