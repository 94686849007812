import React from "react";
import { Tabs, Tab } from "./Tab";
import Curriculum from "../../pages/tutor/Curriculum";
import StudentAttendance from "../../pages/tutor/StudentAttendance";

function TutorTabs() {
  return (
    <div className="ml-10">
      <Tabs>
        <Tab className="" label="Students' Attendance">
          <StudentAttendance />
        </Tab>
        <Tab label="Lesson Log">
          <Curriculum />
        </Tab>
      </Tabs>
    </div>
  );
}

export default TutorTabs;
