/** @format */

import React, {useState, useEffect} from "react";
import {fetch, scrollToTop} from "../../service/utils";
import {BeatLoader} from "react-spinners";
import {theme} from "antd";

function LearnerAvailable(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [networkError, setNetworkError] = useState("");
  const [available, setAvailable] = useState([]);
  // *get available candidates start
  const getCandidates = async () => {
    setNetworkError("");
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = {Authorization: `Bearer ${token}`};
      const response = await fetch(
        "/enabler/available-candidates",
        "get",
        null,
        headers
      );
      setIsLoading(false);

      if (response.data.ok === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
      }

      setAvailable(response.data.availableCandidates);
      setIsLoading(false);
      setNetworkError("");
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    } finally {
    }
  };
  useEffect(() => {
    getCandidates();
    scrollToTop();
  }, []);
  // *get available candidates end

  const {
    token: {colorBgContainer},
  } = theme.useToken();

  // Pageination start here
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    available && available.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(available && available.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }
  return (
    <>
      <div className=" font-semibold w-full mt-6 text-center">
        <h1 className="text-center text-xl text-[#00009c]  ">
          Available Candidates
        </h1>
      </div>
      <div
        style={{
          padding: 6,
          minHeight: 360,
          background: colorBgContainer,
        }}
      >
        {isLoading && (
          <div className="flex justify-center h-screen items-center">
            <BeatLoader color="#070756" loading={true} size={15} />
          </div>
        )}

        {networkError && isLoading === false && (
          <div className="text-center mt-8 mb-[371px]">
            <strong className="font-bold text-red-600">{networkError}</strong>
          </div>
        )}

        {!isLoading && !networkError && (
          <div className=" mb-[265px] flex flex-col border-1 mt-2">
            <div className="overflow-x-auto sm:-mx-4 lg:-mx-2">
              <div className="inline-block min-w-[100%] sm:px-6 lg:px-6">
                <div className="overflow-hidden">
                  <table className="mt-4 rounded-xl max-[640px]:mx-2 w-[100%] text-center text-md font-light shadow-xl ">
                    <thead className="rounded-xl bg-neutral-200">
                      <tr className="">
                        <th scope="col" className="px-2 py-2">
                          S. No.
                        </th>
                        <th scope="col" className=" px-2 py-2">
                          Name
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Email
                        </th>
                        <th scope="col" className=" px-2 py-2">
                          Contact
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Course
                        </th>
                        <th scope="col" className=" px-2 py-2">
                          Qualification
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Gender
                        </th>
                        <th scope="col" className=" px-2 py-2">
                          Address
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Achievements
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {available &&
                        currentItems.map((learner, ind) => {
                          return (
                            <tr
                              key={learner}
                              className="border-b bg-neutral-100 transition duration-300 ease-in-out hover:bg-neutral-200 hover:text-black"
                            >
                              <td className="whitespace-nowrap px-6 py-2 font-medium">
                                {ind + 1}
                              </td>
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.user_name}
                              </td>
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.user_email}
                              </td>
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.user_mobile_no}
                              </td>
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.program_name}
                              </td>
                              <td className=" px-4 py-2">
                                {learner.participant_qualification &&
                                  learner.participant_qualification
                                    .slice(0, 1)
                                    .map((edu) => {
                                      return <span>{edu.degree}</span>;
                                    })}
                              </td>
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.participant_gender}
                              </td>
                              <td className=" px-3 py-2">
                                {`${
                                  JSON.parse(learner.learner_address)
                                    .user_address
                                },
                                  ${
                                    JSON.parse(learner.learner_address)
                                      .user_city
                                  },
                                  ${
                                    JSON.parse(learner.learner_address)
                                      .user_state
                                  },
                                  ${
                                    JSON.parse(learner.learner_address)
                                      .user_pincode
                                  },
                                  ${
                                    JSON.parse(learner.learner_address)
                                      .user_country
                                  }`}
                              </td>
                              <td className=" px-4 py-2">
                                {learner.participant_achievement &&
                                  learner.participant_achievement.map(
                                    (achiev) => {
                                      return <span>{achiev}</span>;
                                    }
                                  )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="px-5 py-5 border-t flex   flex-row max-[640px]:flex-col bg-white">
              <div className="flex-initial w-[70%] sm:w-[65%] md:w-[80%] lg:w-[90%] text-left">
                <span className="text-xs xs:text-sm text-gray-900 ">
                  {/* Showing 1 to 10 of */}
                  {available && <>Total {currentItems.length} Entries</>}
                </span>
              </div>
              <div className="inline-flex ">
                <button
                  className="text-sm bg-slate-200  hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-l"
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                {pageNumbers.map((number) => (
                  <button
                    key={number}
                    onClick={() => paginate(number)}
                    className={`text-sm bg-slate-200  hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 ${
                      currentPage === number
                        ? "bg-blue-500 text-black border-[1px] border-gray-400"
                        : "text-gray-800"
                    }`}
                  >
                    {number}
                  </button>
                ))}
                <button
                  className="text-sm bg-slate-200  hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-r"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === pageNumbers.length}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default LearnerAvailable;
