import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { fetch, scrollToTop } from "../../service/utils";
import "react-phone-input-2/lib/style.css";
import toast from "react-hot-toast";

import { BeatLoader } from "react-spinners";

function UpdateJob() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const [submitError, setSubmitError] = useState("");
  const [getJobdatabyId, setgetJobdatabyId] = useState([]);

  const [jobLevel, setJobLevel] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [jobLocation, setJobLocation] = useState("");

  const [minQualify, setMinQualify] = useState([]);
  const [minQualification, setMinQualification] = useState("");

  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [minWorkExp, setMinWorkExp] = useState("");
  const [minRelevantExp, setMinRelevantExp] = useState("");

  const [techSkill, setTechSkill] = useState([]);
  const [technicalSkill, setTechnicalSkill] = useState("");

  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [profileSummery, setProfileSummery] = useState("");

  const [jobResponsibilities, setJobResponsibilities] = useState([]);
  const [jobResponsiblity, setJobResponsiblity] = useState("");

  // !touch states start
  const [jobLevelTouch, setJobLevelTouch] = useState(false);
  const [jobTitleTouch, setJobTitleTouch] = useState(false);
  const [employeeTypeTouch, setEmployeeTypeTouch] = useState(false);
  const [jobLocationTouch, setJobLocationTouch] = useState(false);
  const [minQualifyArrayTouch, setMinQualifyArrayTouch] = useState(false);
  const [minAgeTouch, setMinAgeTouch] = useState(false);
  const [maxAgeTouch, setMaxAgeTouch] = useState(false);
  const [minWorkExpTouch, setMinWorkExpTouch] = useState(false);
  const [minRelevantExpTouch, setMinRelevantExpTouch] = useState(false);
  const [techSkillArrayTouch, setTechSkillArrayTouch] = useState(false);
  const [minSalaryTouch, setMinSalaryTouch] = useState(false);
  const [maxSalaryTouch, setMaxSalaryTouch] = useState(false);
  const [profilSummaryTouch, setProfilSummaryTouch] = useState(false);
  const [JobResponsibilityArrayTouch, setJobResponsibilityArrayTouch] =
    useState(false);
  // !touch states end

  useEffect(() => {
    // if (getJobdatabyId.length > 0) {
    setJobLevel(getJobdatabyId[0]?.job_level || "");
    setJobTitle(getJobdatabyId[0]?.job_title || "");
    setEmploymentType(getJobdatabyId[0]?.employee_type || "");
    setJobLocation(getJobdatabyId[0]?.job_location || "");
    setMinQualify(getJobdatabyId[0]?.min_qualification || "");
    setMinAge(getJobdatabyId[0]?.min_age || "");
    setMaxAge(getJobdatabyId[0]?.max_age || "");
    setMinWorkExp(getJobdatabyId[0]?.min_work_exp || 0);
    setMinRelevantExp(getJobdatabyId[0]?.min_relevant_exp || "");
    setTechSkill(getJobdatabyId[0]?.tech_skills || "");
    setMinSalary(getJobdatabyId[0]?.min_salary || "");
    setMaxSalary(getJobdatabyId[0]?.max_salary || "");
    setProfileSummery(getJobdatabyId[0]?.profile_summary || "");
    setJobResponsibilities(getJobdatabyId[0]?.job_responsibility || []);
    // }
  }, [getJobdatabyId]);

  // !dynamic start
  const addJobminQualification = () => {
    if (minQualification) {
      setMinQualify([...minQualify, minQualification]);
      setMinQualification(""); // Clear the input field
      setMinQualifyArrayError("");
    }
  };
  const removeJobminQualification = (indexToRemove) => {
    setMinQualify((prevminQualify) => {
      return prevminQualify.filter((_, index) => index !== indexToRemove);
    });
  };
  const addJobTechnicalSkills = () => {
    if (technicalSkill) {
      setTechSkill([...techSkill, technicalSkill]);
      setTechnicalSkill(""); // Clear the input field
      setTechSkillArrayError("");
    }
  };
  const removeJobTechnicalSkills = (indexToRemove) => {
    setTechSkill((prevtechSkill) => {
      return prevtechSkill.filter((_, index) => index !== indexToRemove);
    });
  };
  const addJobResponsibility = () => {
    if (jobResponsiblity) {
      setJobResponsibilities([...jobResponsibilities, jobResponsiblity]);
      setJobResponsiblity(""); // Clear the input field
      setJobResponsibilityArrayError("");
    }
  };
  const removeJobResponsibility = (indexToRemove) => {
    setJobResponsibilities((prevResponsibilities) => {
      return prevResponsibilities.filter((_, index) => index !== indexToRemove);
    });
  };
  // !dynamic end

  // *get start
  const jobId = useParams();
  const job_id = jobId.id;
  const getUpdateJobId = async () => {
    setDataLoading(true);
    setNetworkError("");
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/enabler/getjobbyid",
        "Post",
        { job_id },
        headers
      );
      if (response.data.ok === false) {
        setDataLoading(false);
        setNetworkError(response.data.message);
      }
      setJobLevel(response.data.getjobdataById[0]?.job_level || "");
      setJobTitle(response.data.getjobdataById[0]?.job_title || "");
      setEmploymentType(response.data.getjobdataById[0]?.employee_type || "");
      setJobLocation(response.data.getjobdataById[0]?.job_location || "");
      setMinQualify(response.data.getjobdataById[0]?.min_qualification || []);
      setMinAge(response.data.getjobdataById[0]?.min_age || "");
      setMaxAge(response.data.getjobdataById[0]?.max_age || "");
      setMinWorkExp(response.data.getjobdataById[0]?.min_work_exp || 0);
      setMinRelevantExp(
        response.data.getjobdataById[0]?.min_relevant_exp || ""
      );
      setTechSkill(response.data.getjobdataById[0]?.tech_skills || "");
      setMinSalary(response.data.getjobdataById[0]?.min_salary || "");
      setMaxSalary(response.data.getjobdataById[0]?.max_salary || "");
      setProfileSummery(response.data.getjobdataById[0]?.profile_summary || "");
      setJobResponsibilities(
        response.data.getjobdataById[0]?.job_responsibility || []
      );
      setgetJobdatabyId(response.data.getjobdataById);
      setDataLoading(false);
      setNetworkError("");
    } catch (err) {
      setDataLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
        toast.error("Something Went Wrong. Please Try Again Later.");
      }
    } finally {
    }
  };
  // *get end

  const info = {
    job_level: jobLevel,
    job_title: jobTitle,
    employee_type: employmentType,
    job_location: jobLocation,
    min_qualification: minQualify,
    min_age: minAge,
    max_age: maxAge,
    min_work_exp: minWorkExp,
    min_relevant_exp: minRelevantExp,
    tech_skills: techSkill,
    min_salary: minSalary,
    max_salary: maxSalary,
    profile_summary: profileSummery,
    job_responsibility: jobResponsibilities,
  };

  useEffect(() => {
    getUpdateJobId();
  }, [job_id]);
  //======================Update Form validation Start Here===============================================
  const [jobLevelError, setJobLevelError] = useState("");
  const [jobTitleError, setJobTitleError] = useState("");
  const [employeeTypeError, setEmployeeTypeError] = useState("");
  const [jobLocationError, setJobLocationError] = useState("");
  const [minQualifyArrayError, setMinQualifyArrayError] = useState("");
  const [minAgeError, setMinAgeError] = useState("");
  const [maxAgeError, setMaxAgeError] = useState("");
  const [minWorkExpError, setMinWorkExpError] = useState("");
  const [minRelevantExpError, setMinRelevantExpError] = useState("");
  const [techSkillArrayError, setTechSkillArrayError] = useState("");
  const [minSalaryError, setMinSalaryError] = useState("");
  const [maxSalaryError, setMaxSalaryError] = useState("");
  const [profilSummaryError, setProfilSummaryError] = useState("");
  const [JobResponsibilityArrayError, setJobResponsibilityArrayError] =
    useState("");

  const validateJobLevel = (e) => {
    if (e === "L1" || e === "L2" || e === "L3") {
      setJobLevelError("");
      return true;
    } else {
      setJobLevelError("Invalid job level.");
      return false;
    }
  };
  const handleBlurJobLevel = (e) => {
    setJobLevelTouch(true);
    validateJobLevel(e);
  };

  const validateJobTitle = (e) => {
    if (e.trim() === "") {
      setJobTitleError("Please Enter Job Title.");
      return false;
    }
    setJobTitleError("");
    return true;
  };
  const handleBlurTitle = (e) => {
    setJobTitleTouch(true);
    validateJobTitle(e);
  };

  const validateJobEmployeeType = (e) => {
    if (e === "Permanent" || e === "Full-Time" || e === "Part-Time") {
      setEmployeeTypeError("");
      return true;
    } else {
      setEmployeeTypeError("Invalid Employment Type.");
      return false;
    }
  };
  const handleBlurJobEmployeeType = (e) => {
    setEmployeeTypeTouch(true);
    validateJobEmployeeType(e);
  };

  const validateJobLocation = (e) => {
    if (e.trim() === "") {
      setJobLocationError("Please Enter Job Location.");
      return false;
    }
    setJobLocationError("");
    return true;
  };
  const handleBlurLocation = (e) => {
    setJobLocationTouch(true);
    validateJobLocation(e);
  };

  const validateMinQualification = (e) => {
    if (minQualify.length === 0) {
      if (e.length === 0) {
        setMinQualifyArrayError("Please Enter Minimum Qualification.");
        return false;
      }
      setMinQualifyArrayError("Please Enter Minimum Qualification.");
      return false;
    }
    setMinQualifyArrayError("");
    return true;
  };
  const handleBlurMinQualification = (e) => {
    setMinQualifyArrayTouch(true);
    validateMinQualification(e);
  };

  const validateMinAge = (e) => {
    if (e === "") {
      setMinAgeError("Please Enter Minimum Age.");
      return false;
    }
    const ageRegex = /^\d{2}$/;
    if (!ageRegex.test(e)) {
      setMinAgeError("Please Enter 2 digits Only.");
      return false;
    }

    setMinAgeError("");
    return true;
  };

  const handleBlurMinAge = (e) => {
    setMinAgeTouch(true);
    validateMinAge(e);
  };

  const validateMaxAge = (e) => {
    if (e === "") {
      setMaxAgeError("Please Enter Maximum Age.");
      return false;
    }

    const ageRegex = /^\d{2}$/;
    if (!ageRegex.test(e)) {
      setMaxAgeError("Please Enter 2 digits Only.");
      return false;
    }
    setMaxAgeError("");
    return true;
  };
  const handleBlurMaxAge = (e) => {
    setMaxAgeTouch(true);
    validateMaxAge(e);
  };

  const validateMinWorkExp = (e) => {
    if (e === "") {
      setMinWorkExpError("Please Enter Minimum Year Of Work Exp.");
      return false;
    }
    const ageRegex = /^\d{1,2}$/;
    if (!ageRegex.test(e)) {
      setMinWorkExpError("Please Enter Exp. in Number Only.");
      return false;
    }
    setMinWorkExpError("");
    return true;
  };
  const handleBlurMinWorkExp = (e) => {
    setMinWorkExpTouch(true);

    validateMinWorkExp(e);
  };

  const validateMinRelevantExp = (e) => {
    if (e === "") {
      setMinRelevantExpError("Please Enter Minimum Year Of Relevant Exp.");
      return false;
    }
    const ageRegex = /^\d{1,2}$/;
    if (!ageRegex.test(e)) {
      setMinRelevantExpError("Please Enter Exp. in Number and digits Only.");
      return false;
    }
    setMinRelevantExpError("");
    return true;
  };
  const handleBlurMinRelevantExp = (e) => {
    setMinRelevantExpTouch(true);

    validateMinRelevantExp(e);
  };

  const validateTechSkill = (e) => {
    if (techSkill.length === 0) {
      if (e.length === 0) {
        setTechSkillArrayError("Please Enter Technical Skills.");
        return false;
      }
      setTechSkillArrayError("Please Enter Technical Skills.");
      return false;
    }
    setTechSkillArrayError("");
    return true;
  };
  const handleBlurTechSkill = (e) => {
    setTechSkillArrayTouch(true);
    validateTechSkill(e);
  };

  const validateMinSalary = (e) => {
    if (e === "") {
      setMinSalaryError("Please Enter Minimum Salary Per Month.");
      return false;
    }
    const ageRegex = /^\d{4,}$/;
    if (!ageRegex.test(e)) {
      setMinSalaryError("Please Enter Min. 4 digits  Number Only.");
      return false;
    }
    setMinSalaryError("");
    return true;
  };
  const handleBlurMinSalary = (e) => {
    setMinSalaryTouch(true);
    validateMinSalary(e);
  };

  const validateMaxSalary = (e) => {
    if (e === "") {
      setMaxSalaryError("Please Enter Maximum Salary Per Month.");
      return false;
    }
    const ageRegex = /^\d{4,}$/;
    if (!ageRegex.test(e)) {
      setMaxSalaryError("Please Enter Min. 4 digits Number Only.");
      return false;
    }
    setMaxSalaryError("");
    return true;
  };
  const handleBlurMaxSalary = (e) => {
    setMaxSalaryTouch(true);
    validateMaxSalary(e);
  };

  const validateProfileSummary = (e) => {
    if (e === "") {
      setProfilSummaryError("Please Enter Profile Summary.");
      return false;
    }
    setProfilSummaryError("");
    return true;
  };
  const handleBlurProfileSummary = (e) => {
    setProfilSummaryTouch(true);
    validateProfileSummary(e);
  };

  const validateJobResponsibility = (e) => {
    if (jobResponsibilities.length === 0) {
      if (e.length === 0) {
        setJobResponsibilityArrayError("Please Enter Job Responsibilities.");
        return false;
      }
      setJobResponsibilityArrayError("Please Enter Job Responsibilities.");
      return false;
    }
    setJobResponsibilityArrayError("");
    return true;
  };

  const handleBlurJobResponsibility = (e) => {
    setJobResponsibilityArrayTouch(true);
    validateJobResponsibility(e);
  };

  useEffect(() => {
    setSubmitError("");
    setNetworkError("");
  }, [
    jobLevel,
    jobTitle,
    employmentType,
    jobLocation,
    minQualify,
    minQualification,
    minAge,
    maxAge,
    minWorkExp,
    minRelevantExp,
    techSkill,
    technicalSkill,
    minSalary,
    maxSalary,
    profileSummery,
    jobResponsibilities,
    jobResponsiblity,
  ]);

  // useEffect(() => {
  //   // if (jobLevelTouch) {
  //   //   handleBlurJobLevel();
  //   // }
  //   if (jobTitleTouch) {
  //     handleBlurTitle();
  //   }
  //   if (employeeTypeTouch) {
  //     handleBlurJobEmployeeType();
  //   }
  //   if (jobLocationTouch) {
  //     handleBlurLocation();
  //   }
  //   if (minQualifyArrayTouch) {
  //     handleBlurMinQualification();
  //   }
  //   if (minAgeTouch) {
  //     handleBlurMinAge();
  //   }
  //   if (maxAgeTouch) {
  //     handleBlurMaxAge();
  //   }
  //   if (minWorkExpTouch) {
  //     handleBlurMinWorkExp();
  //   }
  //   if (minRelevantExpTouch) {
  //     handleBlurMinRelevantExp();
  //   }
  //   if (techSkillArrayTouch) {
  //     handleBlurTechSkill();
  //   }
  //   if (minSalaryTouch) {
  //     handleBlurMinSalary();
  //   }
  //   if (maxSalaryTouch) {
  //     handleBlurMaxSalary();
  //   }
  //   if (profilSummaryTouch) {
  //     handleBlurProfileSummary();
  //   }
  //   if (JobResponsibilityArrayTouch) {
  //     handleBlurJobResponsibility();
  //   }
  // });
  //======================Update Form validation End Here================================================

  const submitHandler = async (e) => {
    e.preventDefault();
    setSubmitError("");

    if (jobLevel === "") {
      setJobLevelError("Please Select a Job Level");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (jobTitle.trim() === "") {
      setJobTitleError("Please Enter a Job Title");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (employmentType === "") {
      setEmployeeTypeError("Please Select an Employment Type.");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (jobLocation === "") {
      setJobLocationError("Please Enter a Job Location.");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (minQualify.length === 0) {
      setMinQualifyArrayError("Please Add Minimum Qualification.");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (minAge === "") {
      setMinAgeError("Please Enter a Minimum Age.");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (maxAgeTouch === "") {
      setMaxAgeError("Please Enter a Maximum Age.");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (minWorkExp === "") {
      setMinWorkExpError("Please Enter Minimum Years of Work Experience.");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (minRelevantExp === "") {
      setMinRelevantExpError(
        "Please Enter Minimum Years of Relevant ExperienceAge."
      );
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (techSkill.length === 0) {
      setTechSkillArrayError("Please Add Technical Skills.");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (minSalary === "") {
      setMinSalaryError("Please Enter Minimum Salary Per Month.");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (maxSalary === "") {
      setMaxSalaryError("Please Enter Maximum Salary Per Month.");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (profileSummery === "") {
      setProfilSummaryError("Please Enter Job Profile Summary.");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    } else if (jobResponsibilities.length === 0) {
      setJobResponsibilityArrayError("Please Add Job Responsibilities.");
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    }

    if (
      jobLevelError ||
      jobTitleError ||
      employeeTypeError ||
      jobLocationError ||
      minQualifyArrayError ||
      minAgeError ||
      maxAgeError ||
      minWorkExpError ||
      minRelevantExpError ||
      techSkillArrayError ||
      minSalaryError ||
      maxSalaryError ||
      profilSummaryError
    ) {
      setSubmitError("Please Fill All The Mandatory Fields Appropriately!");
      return false;
    }
    setIsLoading(true);
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "enabler/add-job",
        "put",
        {
          info,
          job_id,
        },
        headers
      );

      if (response && response.data.ok === false) {
        setIsLoading(false);
        setSubmitError(response.data.message);
        toast.error(response.data.message);
        return false;
      }
      setIsLoading(false);
      navigate("/technohub/enabler/post-job");
      toast.success("Job Updated Successfully!");
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setSubmitError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setSubmitError("Something Went Wrong. Please Try Again Later.");
        toast.error("Something Went Wrong. Please Try Again Later.");
      }
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      {dataLoading && (
        <div className="flex justify-center h-screen items-center">
          <BeatLoader color="#070756" loading={true} size={15} />
        </div>
      )}

      {networkError && dataLoading === false && (
        <div className="text-center mt-8">
          <strong className="font-bold text-red-600">{networkError}</strong>
        </div>
      )}

      {!dataLoading && !networkError && (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            {/* <img
            className="mx-auto h-36 w-auto"
            src={images[`Technohub.jpeg`]}
            alt="Your Company"
          /> */}
            <h2 className="mx-2 text-center text-2xl font-bold leading-9 tracking-tight text-[#070756]">
              Update Job Post
            </h2>
          </div>

          <div className="mt-6 sm:mx-auto md:w-[80%]">
            <form className="space-y-6 " onSubmit={submitHandler}>
              <div className="grid grid-cols-2 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
                <div>
                  <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                    Job Level <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <select
                      defaultValue={jobLevel}
                      onChange={(e) => {
                        setJobLevel(e.target.value);
                        validateJobLevel(e.target.value);
                      }}
                      onBlur={(e) => handleBlurJobLevel(e.target.value)}
                      className="block w-full rounded-md border-0 py-2 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option>Select Level Type</option>
                      <option value="L1">L1</option>
                      <option value="L2">L2</option>
                      <option value="L3">L3</option>
                    </select>
                  </div>
                  {jobLevelError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {jobLevelError}
                    </strong>
                  )}
                </div>
                <div>
                  <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                    Job Title <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="Job-title"
                      name="Job-title"
                      type="text"
                      autoComplete="Job-title"
                      defaultValue={jobTitle}
                      // onChange={(e) => setJobTitle(e.target.value)}
                      onChange={(e) => {
                        setJobTitle(e.target.value);
                        validateJobTitle(e.target.value);
                      }}
                      // value={jobTitle}
                      onBlur={(e) => handleBlurTitle(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {jobTitleError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {jobTitleError}
                    </strong>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
                <div>
                  <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                    Employment Type{" "}
                    <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <select
                      defaultValue={employmentType}
                      onChange={(e) => {
                        setEmploymentType(e.target.value);
                        validateJobEmployeeType(e.target.value);
                      }}
                      // onBlur={}
                      onBlur={(e) => handleBlurJobEmployeeType(e.target.value)}
                      className="block w-full rounded-md border-0 py-2 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option>Select Employment Type</option>
                      <option value="Permanent">Permanent</option>
                      <option value="Full-Time">Full-Time</option>
                      <option value="Part-Time">Part-Time</option>
                    </select>
                  </div>
                  {employeeTypeError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {employeeTypeError}
                    </strong>
                  )}
                </div>
                <div>
                  <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                    Job Location{" "}
                    <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="job-location"
                      name="job-location"
                      type="text"
                      autoComplete="job-location"
                      defaultValue={jobLocation}
                      onChange={(e) => {
                        setJobLocation(e.target.value);
                        validateJobLocation(e.target.value);
                      }}
                      // value={jobLocation}
                      // onBlur={}
                      onBlur={(e) => handleBlurLocation(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {jobLocationError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {jobLocationError}
                    </strong>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:gap-x-8 ">
                <div>
                  <label className="block text-sm font-bold leading-6 text-gray-900">
                    Minimum Qualification{" "}
                    <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="qualification"
                      name="qualification"
                      type="text"
                      // autoComplete="qualification"
                      value={minQualification}
                      onChange={(e) => {
                        setMinQualification(e.target.value);
                        validateMinQualification(e.target.value);
                      }}
                      // onBlur={}
                      onBlur={(e) => handleBlurMinQualification(e.target.value)}
                      className="block w-[85%] max-[610px]:w-[70%] max-[350px]:w-[50%] rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 float-left "
                    />
                    <button
                      type="button"
                      onClick={() => addJobminQualification()}
                      className="bg-green-500 font-bold text-white mx-2 px-4 py-[5px] mb-2 rounded-sm"
                    >
                      Add
                    </button>
                    <div className="">
                      <ul>
                        {minQualify &&
                          minQualify.map((minQualific, index) => (
                            <>
                              <li
                                key={index}
                                className=" border-[2px] rounded-md w-[85%] px-2 my-[1px] p-1 mx-1 font-normal text-sm float-left clear-both"
                              >
                                {minQualific}
                              </li>
                              <div className="float-left">
                                <button
                                  type="button"
                                  onClick={() =>
                                    removeJobminQualification(index)
                                  }
                                  className=" border-2  rounded-md p-1"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="red"
                                    className="w-5 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </>
                          ))}
                      </ul>
                    </div>
                  </div>

                  {minQualifyArrayError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {minQualifyArrayError}
                    </strong>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
                <div>
                  <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                    Minimum Age{" "}
                    <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="min-age"
                      name="min-age"
                      type="text"
                      autoComplete="min-age"
                      defaultValue={minAge}
                      onChange={(e) => {
                        setMinAge(e.target.value);
                        validateMinAge(e.target.value);
                      }}
                      // onBlur={}
                      onBlur={(e) => handleBlurMinAge(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {minAgeError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {minAgeError}
                    </strong>
                  )}
                </div>
                <div>
                  <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                    Maximum Age{" "}
                    <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="min-age"
                      name="min-age"
                      type="text"
                      autoComplete="min-age"
                      defaultValue={maxAge}
                      onChange={(e) => {
                        setMaxAge(e.target.value);
                        validateMaxAge(e.target.value);
                      }}
                      // onBlur={}
                      onBlur={(e) => handleBlurMaxAge(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {maxAgeError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {maxAgeError}
                    </strong>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
                <div>
                  <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                    Minimum Year of Work Experiance{" "}
                    <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="min-age"
                      name="min-age"
                      type="text"
                      autoComplete="min-age"
                      defaultValue={minWorkExp}
                      onChange={(e) => {
                        setMinWorkExp(e.target.value);
                        validateMinWorkExp(e.target.value);
                      }}
                      // onBlur={}
                      onBlur={(e) => handleBlurMinWorkExp(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {minWorkExpError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {minWorkExpError}
                    </strong>
                  )}
                </div>
                <div>
                  <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                    Minimum Year of Relevant Experiance{" "}
                    <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="min-age"
                      name="min-age"
                      type="text"
                      autoComplete="min-age"
                      defaultValue={minRelevantExp}
                      onChange={(e) => {
                        setMinRelevantExp(e.target.value);
                        validateMinRelevantExp(e.target.value);
                      }}
                      // onBlur={}
                      onBlur={(e) => handleBlurMinRelevantExp(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {minRelevantExpError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {minRelevantExpError}
                    </strong>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:gap-x-8 ">
                <div>
                  <label className="block text-sm font-bold leading-6 text-gray-900">
                    Technical Skills{" "}
                    <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="qualification"
                      name="qualification"
                      type="text"
                      autoComplete="qualification"
                      value={technicalSkill}
                      onChange={(e) => {
                        setTechnicalSkill(e.target.value);
                        validateTechSkill(e.target.value);
                      }}
                      // onBlur={}
                      onBlur={(e) => handleBlurTechSkill(e.target.value)}
                      className="block w-[85%] max-[610px]:w-[70%] max-[350px]:w-[50%] rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 float-left "
                    />
                    <button
                      type="button"
                      onClick={() => addJobTechnicalSkills()}
                      className="bg-green-500 font-bold text-white mx-2 px-4 py-[5px] mb-2 rounded-sm"
                    >
                      Add
                    </button>
                    <div className="">
                      <ul>
                        {techSkill &&
                          techSkill.map((techskill, index) => (
                            <>
                              <li
                                key={index}
                                className=" border-[2px] rounded-md w-[85%] px-2 my-[1px] p-1 mx-1 font-normal text-sm float-left clear-both"
                              >
                                {techskill}
                              </li>
                              <div className="float-left">
                                <button
                                  type="button"
                                  onClick={() =>
                                    removeJobTechnicalSkills(index)
                                  }
                                  className=" border-2  rounded-md p-1"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="red"
                                    className="w-5 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </>
                          ))}
                      </ul>
                    </div>
                  </div>
                  {techSkillArrayError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {techSkillArrayError}
                    </strong>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
                <div>
                  <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                    Min. Salary Per Month{" "}
                    <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="min-age"
                      name="min-age"
                      type="text"
                      autoComplete="min-age"
                      defaultValue={minSalary}
                      onChange={(e) => {
                        setMinSalary(e.target.value);
                        validateMinSalary(e.target.value);
                      }}
                      // onBlur={}
                      onBlur={(e) => handleBlurMinSalary(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {minSalaryError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {minSalaryError}
                    </strong>
                  )}
                </div>
                <div>
                  <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                    Max. Salary Per Month{" "}
                    <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="min-age"
                      name="min-age"
                      type="text"
                      autoComplete="min-age"
                      defaultValue={maxSalary}
                      onChange={(e) => {
                        setMaxSalary(e.target.value);
                        validateMaxSalary(e.target.value);
                      }}
                      // onBlur={}
                      onBlur={(e) => handleBlurMaxSalary(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {maxSalaryError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {maxSalaryError}
                    </strong>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:gap-x-8 ">
                <div>
                  <label className="block text-sm font-bold leading-6 text-gray-900">
                    Profile Summary{" "}
                    <span className="text-red-600 font-bold">*</span>
                  </label>

                  <div className="mt-2">
                    <TextArea
                      id="address"
                      name="address"
                      type="text"
                      rows={3}
                      defaultValue={profileSummery}
                      onChange={(e) => {
                        setProfileSummery(e.target.value);
                        validateProfileSummary(e.target.value);
                      }}
                      // onBlur={}
                      onBlur={(e) => handleBlurProfileSummary(e.target.value)}
                      className="block w-full rounded-md border-0  px-2 py-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  {profilSummaryError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {profilSummaryError}
                    </strong>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:gap-x-8 ">
                <div>
                  <label className="block text-sm font-bold leading-6 text-gray-900">
                    Job Responsibilities{" "}
                    <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2 ">
                    <input
                      id="jobResponsiblity"
                      name="jobResponsiblity"
                      type="text"
                      // autoComplete="qualification"
                      value={jobResponsiblity}
                      onChange={(e) => {
                        setJobResponsiblity(e.target.value);
                        validateJobResponsibility(e.target.value);
                      }}
                      // onBlur={}
                      onBlur={(e) =>
                        handleBlurJobResponsibility(e.target.value)
                      }
                      className="block w-[85%] max-[610px]:w-[70%] max-[350px]:w-[50%] rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 float-left "
                    />
                    <button
                      type="button"
                      onClick={() => addJobResponsibility()}
                      className="bg-green-500 font-bold text-white mx-2 px-4 py-[5px] mb-2 rounded-sm"
                    >
                      Add
                    </button>
                    <div className="">
                      <ul>
                        {jobResponsibilities &&
                          jobResponsibilities.map((responsibility, index) => (
                            <>
                              <li
                                key={index}
                                className=" border-[2px] rounded-md w-[85%] px-2 my-[1px] p-1 mx-1 font-normal text-sm float-left clear-both"
                              >
                                {responsibility}
                              </li>
                              <div className="float-left">
                                <button
                                  type="button"
                                  onClick={() => removeJobResponsibility(index)}
                                  className=" border-2  rounded-md p-1"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="red"
                                    className="w-5 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </>
                          ))}
                      </ul>
                    </div>
                  </div>
                  {JobResponsibilityArrayError && (
                    <strong className="text-red-600 text-sm italic text-center">
                      {JobResponsibilityArrayError}
                    </strong>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 max-[640px]:grid-cols-1 mt-4">
                <div className="mx-12 max-[640px]:my-4">
                  <Link to="/technohub/enabler/post-job">
                    <button className="flex w-full justify-center rounded-md bg-red-500  px-3 py-2 text-[16px]  font-medium leading-6 text-white shadow-sm duration-700 hover:bg-[#070756] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Back
                    </button>
                  </Link>
                </div>
                <div className="mx-12">
                  <button
                    type="submit"
                    className={`bg-[#070756] hover:bg-[#00009C] duration-700 w-full rounded-md  px-2 py-2 text-center text-[16px] font-medium text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  ${
                      isLoading ? "cursor-not-allowed" : ""
                    }`}
                  >
                    {isLoading ? "Loading..." : "Update Now"}
                  </button>
                </div>
              </div>
            </form>
            <div className="text-red-600 text-sm italic text-center pt-5">
              {submitError && (
                <strong className="text-red-600 text-sm italic text-center">
                  **{submitError}**
                </strong>
              )}
              {isLoading && (
                <strong className="text-blue-800 text-sm text-center">
                  Your Job Post Is Being Updated...
                </strong>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateJob;
