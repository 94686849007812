import React, { useEffect } from "react";
import { scrollToTop } from "../service/utils";

export default function PrivacyPolicy() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-[90%] max-[640px]:p-10 sm:py-16 sm:px-6 md:py-20 lg:py-24">
        <div className="">
          <h1 className=" text-center text-4xl font-bold tracking-tight text-[#424242] sm:text-5xl">
            Privacy
          </h1>
          <p className="mt-10 text-[15px] font-normal leading-6 text-gray-600">
            Lavanya Gurukul Center Privacy Policy has adopted this privacy
            policy in order to ensure users of our commitment and dedication to
            privacy. The following privacy guidelines apply to the website
            "www.lavanyagurukul.com" (Site). Lavanya Gurukul Center respects
            your right to privacy. (Lavanya Gurukul Center) invite you to visit
            our Site to search or browse the Site without revealing who you are
            or registering with us. However, if you choose to give Lavanya
            Gurukul Center personal information via the Internet, for example in
            response to the careers section, that Lavanya Gurukul Center may
            need; it is our intent to let you know how Lavanya Gurukul Center
            will use such information. If you tell us that you do not wish to
            have this information used as a basis for further contract with you,
            we will Endeavour to respect your wishes.
          </p>
        </div>
        <div className="mt-6">
          <h1 className=" text-xl font-bold tracking-tight text-[#424242] ">
            Collection of IP addresses and the use of "Cookies"
          </h1>
          <p className="mt-3 text-[15px] font-medium  leading-6 text-gray-600">
            When you visit our Site, some information such as your Internet
            protocol address, Internet service provider, operating system, the
            Site from which you arrived, and the time and date of your visit may
            be collected automatically as part of the software operation of this
            Site. This intake of information is entirely anonymous. Lavanya
            Gurukul Center uses this information solely for internal marketing
            purposes, for example, to see what pages are most frequently visited
            in order to improve the Site and also potentially for investor
            relations and HR purposes.
          </p>

          <p className="mt-3 text-[15px] font-medium  leading-6 text-gray-600">
            Lavanya Gurukul Center also collects information through the use of
            a technology called "cookies". A cookie is a small file that a web
            site can send to your browser, which is then stored on your system
            by your browser. The use of cookie technology on
            www.lavanyagurukul.com is solely for internal marketing purposes. If
            you are uncomfortable accepting cookies from our Site or any other,
            you can set your browser to notify you when a Site attempts to send
            you a cookie, giving you the opportunity to decide for yourself
            whether or not to accept the cookies. You can also set your browser
            to turn off cookies.
          </p>

          <h1 className="mt-4 text-xl font-bold tracking-tight text-[#424242] ">
            Personal Information
          </h1>
          <p className="mt-3 text-[15px] font-medium  leading-6 text-gray-600">
            Whenever Lavanya Gurukul Center collects information that you
            voluntarily submit, it is Lavanya Gurukul Center intent to inform
            you of why this information is being requested and how it is going
            to be used. Lavanya Gurukul Center will not sell, distribute, or
            give your personal information to any third party without your
            knowledge and consent. Lavanya Gurukul Center shall not be liable
            for any personal information that you submit to external vendors or
            to any web site linked to this Site.
          </p>

          <h1 className="mt-4 text-xl font-bold tracking-tight text-[#424242] ">
            Nature of Data on the Internet
          </h1>
          <p className="mt-3 text-[15px] font-medium  leading-6 text-gray-600">
            It is Lavanya Gurukul Center intent to keep secure any personal
            information that you submit to us, and Lavanya Gurukul Center will
            continue to take steps to maintain the security of this Site.
            However, the open nature of the Internet is such that data may flow
            over networks without security measures and may be accessed and used
            by people other than those for whom the data is intended. Therefore,
            in submitting personal information to the Site, you assume the risk
            of a third party obtaining that information.
          </p>

          <h1 className=" mt-4 text-xl font-bold tracking-tight text-[#424242] ">
            Important security information - email scam
          </h1>
          <p className="mt-3 text-[15px] font-medium  leading-6 text-gray-600">
            The Lavanya Gurukul Center website team has been made aware of a
            number of fake emails that appear to be sent from Lavanya Gurukul
            Center asking users to confirm their email address and/or password.
            We would like to reassure users that we will never send you an email
            asking you to reconfirm your security details. We will never ask you
            to divulge your full passwords via e-mail.
          </p>
          <p className="mt-3 text-[15px] font-medium  leading-6 text-gray-600">
            If you do receive a suspicious email like this please delete it
            straight away and do not click on any of the links contained in it.
            If you are ever concerned about any communications that are or seem
            to be from Lavanya Gurukul Center, please contact us immediately.
          </p>
        </div>
      </div>
    </div>
  );
}
