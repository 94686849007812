/** @format */

// import React, { useEffect, useState } from "react";
// import { Switch } from "@headlessui/react";
// import { fetch } from "../service/utils";
// import toast from "react-hot-toast";

// const ToggleSwitch = ({ id, changeStatus, url, getData, enbData }) => {
//   const [enabled, setEnabled] = useState(changeStatus == 1 ? true : false);
//   const [state, setState] = useState();
//   const [errorMessage, setErrorMessage] = useState("");
//   useEffect(() => {
//     setState(enabled ? 1 : 0);
//   }, [enabled]);

//   useEffect(() => {
//     setEnabled(changeStatus == 1 ? true : false);
//   }, [changeStatus]);

//   const activateHandler = () => {
//     setState(enabled === true ? 1 : 0);
//     const changeStatus = async () => {
//       try {
//         const token = localStorage.getItem("lgs_token");
//         const headers = { Authorization: `Bearer ${token}` };
//         const body =
//           enbData && typeof enbData === "object"
//             ? { data: enbData, state }
//             : { state };
//         const response = await fetch(`${url}${id}`, "patch", body, headers);
//         if (response.ok === false) {
//           setEnabled(false);
//           setErrorMessage(response.data.message);
//         }
//         getData();
//         toast.success(response.data.message);
//       } catch (err) {
//         setEnabled(false);
//         if (
//           err?.response &&
//           err?.response?.data &&
//           err?.response?.data?.message
//         ) {
//           setErrorMessage(err?.response?.data?.message);
//         } else {
//           setErrorMessage("Unable to update status.");
//         }
//       } finally {
//       }
//     };
//     changeStatus();
//   };

//   return (
//     <>
//       <Switch
//         onClick={activateHandler}
//         checked={enabled}
//         onChange={setEnabled}
//         className={`${enabled ? "bg-teal-700" : "bg-red-700"}
//           relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
//       >
//         <span className="sr-only">Use setting</span>
//         <span
//           aria-hidden="true"
//           className={`${enabled ? "translate-x-9" : "translate-x-0"}
//           pointer-events-none inline-block h-[20px] w-[22px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
//         />
//       </Switch>
//       <br />
//       {errorMessage && (
//         <strong className="text-red-500 text-sm font-bold italic text-center">
//           {errorMessage}
//         </strong>
//       )}
//     </>
//   );
// };

// export default ToggleSwitch;

import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { fetch } from "../service/utils";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import ConfirmModal from "./modals/ConfirmModal";

const ToggleSwitch = ({ id, changeStatus, url, getData, enbData }) => {
  const [enabled, setEnabled] = useState(changeStatus == 1 ? true : false);
  const [state, setState] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    setState(enabled ? 1 : 0);
  }, [enabled]);

  useEffect(() => {
    setEnabled(changeStatus == 1 ? true : false);
  }, [changeStatus]);

  const changeStatusFunc = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const body =
        enbData && typeof enbData === "object"
          ? { data: enbData, state }
          : { state };
      const response = await fetch(`${url}${id}`, "patch", body, headers);
      if (response.ok === false) {
        setLoading(false);
        // setEnabled(!enabled); // Revert the switch to its original state
        setErrorMessage(response.data.message);
      } else {
        getData();
        setLoading(false);
        toast.success(response.data.message);
      }
    } catch (err) {
      setLoading(false);
      // setEnabled(!enabled); // Revert the switch to its original state
      if (
        err?.response &&
        err?.response?.data &&
        err?.response?.data?.message
      ) {
        setErrorMessage(err?.response?.data?.message);
      } else {
        setErrorMessage("Unable to update status.");
      }
    }
  };

  const [confirm, setConfirm] = useState(false);
  const [confirmChange, setConfirmChange] = useState(false);

  let getApproval = (approve) => {
    setConfirmChange(approve);
  };

  const activateHandler = () => {
    setErrorMessage("");
    setConfirm(true);

    if (confirmChange) {
      setState(enabled === true ? 1 : 0);
      changeStatusFunc();
      setConfirm(false);
      setConfirmChange(false);
    } else {
      setEnabled(enabled);
    }
  };


  useEffect(() => {
    if (confirmChange) {
      activateHandler();
    }
  }, [confirmChange]);

  return (
    <>
      <Switch
        onClick={activateHandler}
        checked={enabled}
        // onChange={setEnabled}
        className={`${enabled ? "bg-teal-700" : "bg-red-700"}
          relative inline-flex h-[20px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${enabled ? "translate-x-[29px]" : "translate-x-0"}
          pointer-events-none inline-block h-[16px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
      <br />
      <div>
        <BeatLoader color="#00009C" loading={loading} size={7} />
      </div>
      {errorMessage && (
        <strong className="text-red-600 text-xs font-semibold text-center">
          {errorMessage}
        </strong>
      )}
      <ConfirmModal
        confirm={confirm}
        setConfirm={setConfirm}
        getApproval={getApproval}
      />
    </>
  );
};

export default ToggleSwitch;
