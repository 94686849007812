import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import ErrorPage from "../pages/ErrorPage";
import TutorLogin from "../pages/tutor/TutorLogin";
import TutorDashboard from "../pages/tutor/TutorDashboard";
import TutorLoginMiddleware from "../middleware/tutor-login-middleware";
import TutorTokenMiddleware from "../middleware/tutor-token-middleware";
import TutorChangePwd from "../pages/tutor/TutorChangePwd";
import { useDispatch } from "react-redux";
import { fetch } from "../service/utils";
import { setAssignBatchesStudent } from "../redux/features/assigned-batches-students";

const Tutor = () => {
  const [tableLoading, setTableLoading] = useState(true);
  const [networkError, setNetworkError] = useState("");

  const dispatch = useDispatch();
  const token = localStorage.getItem("lgs_token");
  const getAssignStudentBathes = async () => {
    setNetworkError("");
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/tutor/assigned-batches-students",
        "get",
        null,
        headers
      );
      if (response.data.ok === false) {
        setTableLoading(false);
        setNetworkError(response.data.message);
      }
      dispatch(setAssignBatchesStudent(response.data.assignedBatches));
      setTableLoading(false);
      setNetworkError("");
    } catch (err) {
      setTableLoading(false);
      setNetworkError(err?.response?.data?.message);
    } finally {
    }
  };
  useEffect(() => {
    getAssignStudentBathes();
  }, [token]);
  return (
    <Routes>
      <Route
        path="login"
        element={
          <TutorLoginMiddleware>
            <TutorLogin />{" "}
          </TutorLoginMiddleware>
        }
      />
      <Route
        path="changepwd"
        element={
          <TutorTokenMiddleware>
            <TutorChangePwd />
          </TutorTokenMiddleware>
        }
      />
      <Route
        path="dashboard/*"
        element={
          <TutorTokenMiddleware>
            <TutorDashboard dashError={networkError} />
          </TutorTokenMiddleware>
        }
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Tutor;
