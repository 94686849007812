import { AcademicCapIcon } from "@heroicons/react/24/outline";

import { DesktopComputerOutline } from "heroicons-react";
import React, { useEffect, useState } from "react";
import { fetch, scrollToTop } from "../../service/utils";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";

const TutorHome = ({ dashError }) => {
  const [tableLoading, setTableLoading] = useState(true);
  const [networkError, setNetworkError] = useState("");
  const [getAssigninfobatch, setgetAssigninfobatch] = useState([]);

  const getAssigninfo = async () => {
    setNetworkError("");
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/tutor/get-dashboard-details",
        "get",
        null,
        headers
      );
      if (response.data.ok === false) {
        setTableLoading(false);
        setNetworkError(response.data.message);
      }

      setgetAssigninfobatch(response.data.details);
      setTableLoading(false);
      setNetworkError("");
    } catch (err) {
      setTableLoading(false);
      setNetworkError(err?.response?.data?.message);
    } finally {
    }
  };
  useEffect(() => {
    getAssigninfo();
    scrollToTop();
  }, []);

  return (
    <>
      {tableLoading === true && (
        <div className="w-[100%] flex justify-center h-screen items-center">
          <BeatLoader color="#00009C" loading={true} size={15} />
        </div>
      )}
      <div className="w-full text-center mt-8">
        <h1 className="text-2xl font-semibold mb-4 text-[#00009C]">
          Dashboard
        </h1>
      </div>

      {!tableLoading && dashError && (
        <div className="text-center text-red-700 font-semibold ">{dashError}</div>
      )}

      {!tableLoading && networkError && (
        <div className="text-center text-red-700 font-semibold">{networkError}</div>
      )}

      {!tableLoading &&
        getAssigninfobatch &&
        getAssigninfobatch[0].batch_details &&
        getAssigninfobatch[0].batch_details === null &&
        getAssigninfobatch[0].program_details === null && (
          <div className="text-center text-red-700 text-xl mt-5">
            No assignment has been done yet.
          </div>
        )}
      <div className="-mx-4 flex flex-wrap p-8">
        {getAssigninfobatch[0]?.program_details !== null && (
          <div className="w-full px-4 md:w-1/2 lg:w-1/3  ">
            <div className="mb-9 rounded-xl py-8 px-7 shadow-lg transition-all shadow-[#4a546b] hover:shadow-[#54657a] hover:shadow-inner sm:p-9 lg:px-6 xl:px-9 bg-slate-300 ">
              <div className="mx-auto mb-20 ">
                <DesktopComputerOutline className="w-14 h-14 text-[#070756] float-left" />
                <h3 className="ml-4 mt-2 text-xl font-bold text-[#00009C]   sm:text-2xl lg:text-xl xl:text-2xl float-right">
                  Programs
                </h3>
              </div>
              <div className="h-[197px]">
                {getAssigninfobatch &&
                  getAssigninfobatch[0]?.program_details.map((prog) => {
                    return (
                      <div className="text-base " key={prog.tp_id}>
                        <ul className="text-base  font-medium text-body-color   ml-6">
                          <li className="list-disc my-1 text-[#00009C] ">
                            <p>
                              {" "}
                              Program Name:{" "}
                              <span className="text-green-500 text-lg font-medium">
                                {prog.program_name}
                              </span>
                            </p>
                            <p>
                              Duration:{" "}
                              <span className="text-red-500 text-lg font-medium">
                                {prog.program_duration} Months
                              </span>
                            </p>
                          </li>
                        </ul>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        {getAssigninfobatch[0]?.batch_details !== null && (
          <>
            {getAssigninfobatch &&
              getAssigninfobatch[0]?.batch_details.map((batch, ind) => {
                return (
                  <>
                    <div
                      className="w-full px-4 md:w-1/2 lg:w-1/3 "
                      key={batch.m_batch_id}
                    >
                      <Link
                        to={`/tutor/dashboard/batches/${batch.m_batch_id}`}
                        onClick={() => {
                          localStorage.setItem("batch_name", batch.batch_name);
                        }}
                      >
                        <div className="h-[347px] mb-9 rounded-xl py-8 px-7 shadow-xl shadow-[#687186] transition-all  hover:shadow-inner sm:p-9 lg:px-6 xl:px-9 hover:shadow-[#4a546b]">
                          <div className="mx-auto mb-20">
                            <AcademicCapIcon className="w-14 h-14 text-[#070756] float-left" />
                            <h3 className=" mt-2 text-xl font-bold text-[#00009C]   sm:text-2xl lg:text-xl xl:text-2xl float-right">
                              {batch.batch_name}
                            </h3>
                          </div>
                          <div>
                            <p className="text-base text-center font-normal text-body-color text-[#00009C] hover:text-[#070756] ml-6 ">
                              {" "}
                              Student Count{" "}
                              <span className="text-green-500 text-xl font-semibold">
                                {batch &&
                                  batch?.batch_students &&
                                  batch?.batch_students.length}
                              </span>
                            </p>
                          </div>
                          <div className="mt-6">
                            <ul className="text-base  font-medium text-body-color  ml-6">
                              <li className="list-disc my-1 text-[#00009C]">
                                Start & End Time:{" "}
                                <p>
                                  <span className="text-green-500 ">
                                    {batch.start_time.slice(0, 8)}
                                  </span>{" "}
                                  To{" "}
                                  <span className="text-red-500 ">
                                    {batch.end_time.slice(0, 8)}
                                  </span>
                                </p>
                              </li>
                              <li className="list-disc my-1 text-[#00009C]">
                                Start & End Date:{" "}
                                <p>
                                  <span className="text-green-500 ">
                                    {batch.start_date}
                                  </span>{" "}
                                  To{" "}
                                  <span className="text-red-500 ">
                                    {batch.end_date}
                                  </span>
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                );
              })}
          </>
        )}
      </div>
    </>
  );
};

export default TutorHome;
