import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";

import { fetch, formatDate, scrollToTop } from "../../service/utils";
import { theme } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Calendar from "../../component/tutor/Calendar";

const StudentAttendance = () => {
  const { batchId } = useParams();
  const [batch_id, set_batch_id] = useState(batchId);
  useEffect(() => {
    set_batch_id(batchId);
    setAllowUpdate(false);
  }, [batchId]);
  const assignBatches = useSelector(
    (state) => state.AssignBatchStudentData.assignBatchesStudent
  );
  const assignStudentBatch =
    assignBatches && assignBatches.filter((bId) => bId.m_batch_id == batchId);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleCheckboxChange = (event, studentId) => {
    const { checked } = event.target;
    if (checked) {
      setAttendance((prevAttendance) =>
        prevAttendance.map((item) =>
          item.student_id === studentId ? { ...item, attendance: 1 } : item
        )
      );
    } else {
      setAttendance((prevAttendance) =>
        prevAttendance.map((item) =>
          item.student_id === studentId ? { ...item, attendance: 0 } : item
        )
      );
    }
  };
  const [tableLoading, setTableLoading] = useState(true);
  const [networkError, setNetworkError] = useState("");
  const [attendance, setAttendance] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const curDate = formatDate(new Date());
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [showDate, setshowDate] = useState("");

  const getMarkedAttendance = async (date) => {
    setshowDate(date);
    try {
      setTableLoading(true);
      if (date !== curDate) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/tutor/get-marked-attendance",
        "post",
        { batch_id: batchId, date },
        headers
      );
      if (response.data.ok === false) {
        setTableLoading(false);
        let students = assignStudentBatch.batch_students;
        const newAttendance =
          students &&
          students.map((st) => {
            let att_obj = {
              student_id: st.student_id,
              attendance: 1,
            };
            return att_obj;
          });
        setAttendance(newAttendance);
      } else {
        setAttendance(response.data.markedAttendance);
        setAllowUpdate(true);
        setTableLoading(false);
        // setDisableSubmit(true);
      }
    } catch (err) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message === "Attendance hasn't been marked yet."
      ) {
        let students =
          assignStudentBatch && assignStudentBatch[0]?.batch_students;
        const newAttendance =
          students &&
          students.map((st) => {
            let att_obj = {
              student_id: st.student_id,
              attendance: 1,
            };
            return att_obj;
          });
        setAttendance(newAttendance);
      }
      setTableLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  const submitAttendance = async () => {
    setNetworkError("");
    setSubmitLoading(true);
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/tutor/mark-attendance",
        "post",
        { batch_id: batchId, attendance },
        headers
      );
      if (response.data.ok === false) {
        setSubmitLoading(false);
        setNetworkError(response.data.message);
      } else {
        getMarkedAttendance(curDate);
        setSubmitLoading(false);
        toast.success(response.data.message);
      }
    } catch (err) {
      setSubmitLoading(false);
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something went wrong. Please try again later.");
      }
    }
  };

  const updateAttendance = async () => {
    setNetworkError("");
    setSubmitLoading(true);
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/tutor/update-attendance",
        "post",
        { batch_id: batchId, attendance, date: curDate },
        headers
      );
      if (response.data.ok === false) {
        setSubmitLoading(false);
        setNetworkError(response.data.message);
      } else {
        getMarkedAttendance(curDate);
        setSubmitLoading(false);
        toast.success(response.data.message);
      }
    } catch (err) {
      setSubmitLoading(false);
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something went wrong. Please try again later.");
      }
    }
  };

  useEffect(() => {
    getMarkedAttendance(curDate);
  }, [batch_id]);
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <div className="mt-6 flex flex-col ">
        <div className="mx-2" style={{ boxShadow: " 2px 2px 18px 2px gray" }}>
          <Calendar getData={getMarkedAttendance} />
        </div>
      </div>
      <div className="mt-6 flex flex-col ">
        {tableLoading === true && (
          <div className="flex justify-center h-screen items-center">
            <BeatLoader color="#00009C" loading={true} size={15} />
          </div>
        )}

        {!tableLoading && (
          <>
            <div
              className="shadow-sm "
              style={{
                padding: 6,
                minHeight: 360,
                background: colorBgContainer,
              }}
            >
              <div className="overflow-x-auto mt-10">
                <div className="inline-block min-w-[100%]  sm:px-6 lg:px-4">
                  {assignStudentBatch &&
                  assignStudentBatch[0]?.batch_students &&
                  assignStudentBatch[0]?.batch_students.find(
                    (el) => el.student_id !== null
                  ) ? (
                    <div className="overflow-hidden">
                      <p className="font-semibold my-3 text-lg">{showDate}</p>
                      <table className="w-[100%] text-left text-[13px] font-light">
                        <thead className=" md-w-full border-b bg-white font-medium">
                          <tr className="bg-neutral-200">
                            <th scope="col" className="w-[10%] p-4">
                              #
                            </th>
                            <th scope="col" className=" w-[70%]  p-3">
                              Student Name
                            </th>

                            <th scope="col" className="w-[10%]  p-3 ">
                              Select
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {assignStudentBatch[0]?.batch_students &&
                            assignStudentBatch[0]?.batch_students.find(
                              (el) => el.student_id !== null
                            ) &&
                            assignStudentBatch[0]?.batch_students.map(
                              (programs, ind) => {
                                return (
                                  <tr
                                    key={programs.student_id}
                                    className="border-b bg-neutral-100 transition duration-300 ease-in-out hover:bg-neutral-200 hover:text-black"
                                  >
                                    <td className="whitespace-nowrap px-3 py-3 font-medium">
                                      {ind + 1}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-3">
                                      {programs.student_name}
                                    </td>

                                    <td className="whitespace-nowrap px-3 py-3">
                                      {!disableSubmit && (
                                        <input
                                          type="checkbox"
                                          className="w-4 h-4"
                                          disabled={disableSubmit}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              e,
                                              programs.student_id
                                            )
                                          }
                                          checked={
                                            attendance &&
                                            attendance.find(
                                              (el) =>
                                                el.student_id ==
                                                programs.student_id
                                            )?.attendance
                                          }
                                        />
                                      )}
                                      {attendance &&
                                      attendance.find(
                                        (el) =>
                                          el.student_id == programs.student_id
                                      )?.attendance ? (
                                        <span
                                          className={`${
                                            disableSubmit ? "ml-0" : "ml-3"
                                          } text-green-700 font-bold`}
                                        >
                                          Present
                                        </span>
                                      ) : (
                                        <span
                                          className={`${
                                            disableSubmit ? "ml-0" : "ml-3"
                                          } text-red-800 font-bold`}
                                        >
                                          Absent
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          <tr>
                            <td colSpan={5}>
                              {assignStudentBatch &&
                                assignStudentBatch[0]?.batch_students &&
                                assignStudentBatch[0]?.batch_students.find(
                                  (el) => el.student_id !== null
                                ) && (
                                  <div className="text-center">
                                    {!disableSubmit && (
                                      <button
                                        className={`w-40 m-4 py-2 px-3  text-white focus:outline-none rounded-md
                                      
                                      ${
                                        submitLoading
                                          ? `bg-[#070756] cursor-not-allowed`
                                          : "bg-[#070756] hover:bg-[#00009C] transition ease-in-out duration-300"
                                      }
                                     `}
                                        onClick={() => {
                                          if (allowUpdate) {
                                            updateAttendance();
                                          } else {
                                            submitAttendance();
                                          }
                                        }}
                                        disabled={
                                          disableSubmit || submitLoading
                                        }
                                      >
                                        {submitLoading ? (
                                          <BeatLoader
                                            color="#FFFFFF"
                                            loading={true}
                                            size={7}
                                          />
                                        ) : allowUpdate ? (
                                          "Update"
                                        ) : (
                                          "Submit"
                                        )}
                                      </button>
                                    )}
                                  </div>
                                )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="w-[100%] text-center ">
                      <span className="text-red-700 text-lg font-semibold">
                        No students assigned to this batch yet.
                      </span>
                    </div>
                  )}

                  {networkError && (
                    <span className="text-red-700">{networkError}</span>
                  )}
                  {submitLoading && (
                    <div className="text-center">
                      <span className="text-blue-700 text-sm font-semibold">
                        Submitting Attendance...
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StudentAttendance;
