import React from "react";
import { Tabs, Tab } from "../../component/enabler/EnablerJobTabs";
import UpdateJob from "../../component/enabler/UpdateJob";
import HrPolicy from "../../component/enabler/HrPolicy";

function EnablerJobComponent(props) {
  return (
    <div>
      <Tabs>
        <Tab className="" label="Job Definition">
          <UpdateJob />
        </Tab>
        <Tab label="HR Policy">
          <HrPolicy />
        </Tab>
      </Tabs>
    </div>
  );
}

export default EnablerJobComponent;
