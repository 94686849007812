/** @format */

import { BeatLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetch, scrollToTop } from "../../service/utils";
import { DesktopComputerOutline } from "heroicons-react";
import congImg from "../../img/congrats.jpg";

export default function MyBatch() {
  const userData = useSelector((state) => state.user.userData);
  const navigate = useNavigate();

  if (userData.desired_courses === null) {
    navigate("/technohub/learner/allprogram");
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  const [tableLoading, setTableLoading] = useState(true);
  const [tableError, setTableError] = useState(true);
  const [allottedBatch, setAllottedBatch] = useState("");
  const getAssignBatch = async () => {
    setTableError("");
    setTableLoading(true);

    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/learner/allotted-batch",
        "get",
        null,
        headers
      );
      if (response.data.ok === false) {
        setTableLoading(false);
        setTableError(response.data.message);
      } else {
        setAllottedBatch(response.data.allottedBatch);
        setTableLoading(false);
        setTableError("");
      }
    } catch (err) {
      setTableLoading(false);
      setTableError(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAssignBatch();
  }, []);
  return (
    <>
      <div className="mx-auto max-w-3xl max-[662px]:text-center md:text-center lg:text-center">
        <h2 className="text-3xl mt-6 font-bold tracking-tight text-gray-900 sm:text-4xl sm:text-center">
          My Batch
        </h2>
        <p className="mt-6 mb-4 text-lg leading-8 text-gray-700">
          View your assigned batch here.
        </p>
      </div>

      {tableLoading === true && (
        <>
          <div className="flex justify-center items-center mt-40 mb-44">
            <BeatLoader color="#070756" loading={true} size={15} />
            <br />
          </div>
        </>
      )}

      {tableError && tableLoading === false && (
        <div className="text-center mb-80">
          <strong className="text-red-600">{tableError}</strong>
        </div>
      )}

      {!tableLoading && !tableError && (
        <>
          <div className="-mx-4 flex flex-wrap p-8 h justify-center">
            {allottedBatch && allottedBatch?.batch_name !== null && (
              <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                <div
                  className={`mb-9 rounded-xl py-8 px-7 shadow-md shadow-[#00009C] transition-all hover:shadow-lg hover:shadow-[#023e8a]  sm:p-9 lg:px-6 xl:px-9 `}
                >
                  <div className="mx-auto mb-20 ">
                    <DesktopComputerOutline className="w-14 h-14 text-[#070756] float-left" />
                    <h3 className=" mt-2 text-xl font-bold text-[#00009C]   sm:text-2xl lg:text-xl xl:text-2xl float-right">
                      {allottedBatch.batch_name}
                    </h3>
                  </div>
                  <div className="">
                    <div className="text-base ">
                      <ul className="text-base  font-medium text-body-color  ml-6">
                        <li className="list-disc my-1 text-[#00009C] ">
                          Start & End Time:{" "}
                          <p>
                            <span className="text-green-600 text-lg font-medium">
                              {allottedBatch.start_time.slice(0, 8)}{" "}
                            </span>
                            <span className="text-black text-lg font-medium">
                              To{" "}
                            </span>
                            <span className="text-red-600 text-lg font-medium">
                              {allottedBatch.end_time.slice(0, 8)}
                            </span>
                          </p>
                        </li>
                      </ul>
                      <ul className="text-base  font-medium text-body-color  ml-6">
                        <li className="list-disc my-1 text-[#00009C] ">
                          Start & End Date:{" "}
                          <p>
                            <span className="text-green-600 text-lg font-medium">
                              {allottedBatch.start_date}{" "}
                            </span>
                            <span className="text-black text-lg font-medium">
                              To{" "}
                            </span>
                            <span className="text-red-600 text-lg font-medium">
                              {allottedBatch.end_date}
                            </span>
                          </p>
                        </li>
                      </ul>
                      <div className="text-center mt-4">
                        {allottedBatch.complete_status == 1 ? (
                          <span className="text-lg font-medium text-green-600">
                            <img
                              src={congImg && congImg}
                              alt="congrats"
                              className="w-[100%] "
                            />
                            Your Batch has been Completed! <br />
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
