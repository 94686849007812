import React, { useState } from "react";

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const handleClick = (e, newActiveTab) => {
    e.preventDefault();
    setActiveTab(newActiveTab);
  };

  return (
    <div className="mx-3 my-4 ">
      <div className="flex border-b ml-10  mr-3  border-[#070756]  ">
        {children.map((child) => (
          <div className="">
            <button
              key={child.props.label}
              className={`${
                activeTab === child.props.label
                  ? "rounded-t-lg rounded-b text-center px-2 mr-4 text-white bg-[#070756] hover:bg-[#00009C] shadow-lg"
                  : " "
              } flex-1 text-gray-800 font-normal px-2 py-2 mr-4`}
              onClick={(e) => handleClick(e, child.props.label)}
            >
              {child.props.label}
            </button>
          </div>
        ))}
      </div>
      <div className="py-4">
        {children.map((child) => {
          if (child.props.label === activeTab) {
            return <div key={child.props.label}>{child.props.children}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
};

const Tab = ({ label, children }) => {
  return (
    <div label={label} className="hidden">
      {children}
    </div>
  );
};
export { Tabs, Tab };
