import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import EnablerLoginMiddleware from "../middleware/enabler-login-middleware";
import EnablerTokenMiddleware from "../middleware/enabler-token-middleware";
import OfferedPrograms from "../pages/enabler/OfferedPrograms";
import EnablerSponsor from "../pages/enabler/DisplaySponsor";
import EnablerJobComponent from "../pages/enabler/EnablerJobComponent";
import PostJob from "../pages/enabler/PostJob";
import LearnerAvailable from "../pages/enabler/AvailableLearners";
import ErrorPage from "../pages/ErrorPage";

const EnablerChangePwd = lazy(() =>
  import("../pages/enabler/EnablerChangePwd")
);
const EnablerDashboard = lazy(() =>
  import("../pages/enabler/EnablerDashboard")
);
const EnablerLogin = lazy(() => import("../pages/enabler/EnablerLogin"));
const EnablerSignUp = lazy(() => import("../pages/enabler/EnablerSignUp"));
const PaymentStatus = lazy(() => import("../pages/enabler/PaymentStatus"));
const SponsoredBeneficiaries = lazy(() =>
  import("../pages/enabler/SponsoredBeneficiaries")
);

const Enabler = () => {
  return (
    <>
      <Routes>
        <Route
          path="signup"
          element={
            <EnablerLoginMiddleware>
              <EnablerSignUp />
            </EnablerLoginMiddleware>
          }
        />
        <Route
          path="changepwd"
          element={
            <EnablerTokenMiddleware>
              <EnablerChangePwd />
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="login"
          element={
            <EnablerLoginMiddleware>
              <EnablerLogin />{" "}
            </EnablerLoginMiddleware>
          }
        />
        ;
        <Route
          path="sponsors"
          element={
            <EnablerTokenMiddleware>
              <EnablerSponsor />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="dashboard"
          element={
            <EnablerTokenMiddleware>
              <EnablerDashboard />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="payment-status"
          element={
            <EnablerTokenMiddleware>
              <PaymentStatus />
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="programs"
          element={
            <EnablerTokenMiddleware>
              <OfferedPrograms />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="post-job"
          element={
            <EnablerTokenMiddleware>
              <PostJob />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="learner-available"
          element={
            <EnablerTokenMiddleware>
              <LearnerAvailable />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="sponsored-beneficiaries"
          element={
            <EnablerTokenMiddleware>
              <SponsoredBeneficiaries />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="update-job/:id"
          element={
            <EnablerTokenMiddleware>
              <EnablerJobComponent />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default Enabler;
