import React, { useEffect } from "react";
import { scrollToTop } from "../service/utils";
import aboutImg from "../img/about2.avif"
import { MailOutline } from "heroicons-react";
import { MapPinIcon, PhoneArrowDownLeftIcon, PhoneArrowUpRightIcon } from "@heroicons/react/24/outline";
import { PhoneIcon } from "@heroicons/react/24/solid";

export default function ContactUs() {
    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <div className="bg-white">
            <div className="about-container bg-no-repeat bg-cover bg-center h-[400px]" style={{ backgroundImage: `url(${aboutImg})` }}>
                <div className="about-content p-8">
                    <h1 className="text-5xl font-bold mt-32 text-white text-center">Contact Us</h1>
                    {/* <p className="text-lg">
                        jhj
                    </p> */}
                </div>
            </div>
            <div className="mx-auto max-w-[90%] max-[640px]:p-10 sm:pt-16 sm:px-6 md:pt-20 lg:pt-20">
                {/* <div className="">
                    <h1 className=" text-start text-4xl font-bold tracking-tight text-[#424242] ">
                        Vision
                    </h1>
                    <p className="mt-10 text-[15px] font-normal leading-6 text-gray-600">
                        This Website and the information, tools and material contained in it
                        (this "Site") are not directed to, or intended for distribution to
                        or use by, any person or entity who is a citizen or resident of or
                        located in any jurisdiction where such distribution, publication,
                        availability or use would be contrary to law or regulation or which
                        would subject Lavanya Gurukul its affiliates collectively “Lavanya
                        Gurukul " to any registration or licensing requirement within such
                        jurisdiction. This Site is subject to periodic update and revision.
                        Materials should only be considered current as of the date of
                        initial publication appearing thereon, without regard to the date on
                        which you may access the information. Lavanya Gurukul maintains the
                        right to delete or modify information on this Site without prior
                        notice.
                    </p>
                </div> */}
                <div className="my-16 grid grid-cols-1 gap-x-16 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3  ">
                    <div className="text-center  shadow-2xl p-2 rounded-lg">
                        <div className="m-4 flex items-center justify-center text-center">
                            <PhoneArrowUpRightIcon className="w-28 h-28 text-[#00009c]" />
                        </div>

                        <p className=" text-[#070756] text-2xl font-semibold">Call Now</p>
                        <p className=" text-[#00009c] text-lg font-semibold">+91-99779-97799</p>

                    </div>
                    <div className="text-center shadow-2xl p-2 rounded-lg">
                        <div className="m-4 flex items-center justify-center text-center">
                            <MailOutline className="w-28 h-28 text-[#00009c]" />
                        </div>

                        <p className=" text-[#070756] text-2xl font-semibold">E-mail Address</p>
                        <p className=" text-[#00009c] text-lg font-semibold">info@lgscampus.com</p>

                    </div>
                    <div className="text-center shadow-2xl p-2 rounded-lg">
                        <div className="m-4 flex items-center justify-center text-center">
                            <div><MapPinIcon className="w-28 h-28 text-[#00009c]" /></div>

                        </div>

                        <p className=" text-[#070756] text-2xl font-semibold">Campus Address</p>
                        <p className=" text-[#00009c] text-lg font-semibold">LGS School Campus, VIP Road, Near Noor-Us-Sabah Hotel </p>

                    </div>


                </div>

                <form className="my-20">
                    <div className=" grid grid-cols-1 gap-x-16 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3  ">
                        <div >
                            <div className="flex items-center justify-between">
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-bold leading-6 text-gray-900"
                                >
                                    Full Name <span className="text-red-600 font-bold">*</span>
                                </label>
                            </div>
                            <div className="mt-2 flex">
                                <input
                                    // ref={ref}
                                    // id={id}
                                    // name={name}
                                    placeholder="Enter Your Full Name"
                                    // placeholder={placeholder}

                                    type="text"
                                    className={`block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium `}
                                // className={`block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium  ${hasError || isTouch || subError
                                //     ? "border-red-600 bg-red-50"
                                //     : "focus:border-[#070756]"
                                //     }`}
                                // onChange={onChangeHandler}
                                // onKeyDown={onKeyDownHandler}
                                // value={enterValue}
                                // onBlur={() => {
                                //     onBlurHandler();
                                //     setIsTouch("");
                                // }}
                                />
                                {/* {(handleToggleParam === "password" ||
                                handleToggleParam === "con-password") && (
                                    <span
                                        className="flex justify-around items-center cursor-pointer "
                                        onClick={() => handleToggle(handleToggleParam)}
                                    >
                                        <EyeIcon type={type} />
                                    </span>
                                )} */}
                            </div>
                            {/* {hasError && (
                            <div className="flex ml-[0.5px]">
                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                    {errorMessage}
                                </p>
                            </div>
                        )} */}
                            {/* {isTouch && (
                            <div className="flex ml-[0.5px]">
                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                    {isTouch}
                                </p>
                            </div>
                        )} */}
                        </div>
                        <div >
                            <div className="flex items-center justify-between">
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-bold leading-6 text-gray-900"
                                >
                                    Email <span className="text-red-600 font-bold">*</span>
                                </label>
                            </div>
                            <div className="mt-2 flex">
                                <input
                                    // ref={ref}
                                    // id={id}
                                    // name={name}
                                    placeholder="Enter Your Email Address"
                                    // placeholder={placeholder}

                                    type="text"
                                    className={`block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium `}
                                // className={`block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium  ${hasError || isTouch || subError
                                //     ? "border-red-600 bg-red-50"
                                //     : "focus:border-[#070756]"
                                //     }`}
                                // onChange={onChangeHandler}
                                // onKeyDown={onKeyDownHandler}
                                // value={enterValue}
                                // onBlur={() => {
                                //     onBlurHandler();
                                //     setIsTouch("");
                                // }}
                                />
                                {/* {(handleToggleParam === "password" ||
                                handleToggleParam === "con-password") && (
                                    <span
                                        className="flex justify-around items-center cursor-pointer "
                                        onClick={() => handleToggle(handleToggleParam)}
                                    >
                                        <EyeIcon type={type} />
                                    </span>
                                )} */}
                            </div>
                            {/* {hasError && (
                            <div className="flex ml-[0.5px]">
                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                    {errorMessage}
                                </p>
                            </div>
                        )} */}
                            {/* {isTouch && (
                            <div className="flex ml-[0.5px]">
                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                    {isTouch}
                                </p>
                            </div>
                        )} */}
                        </div>
                        <div >
                            <div className="flex items-center justify-between">
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-bold leading-6 text-gray-900"
                                >
                                    Contact Number <span className="text-red-600 font-bold">*</span>
                                </label>
                            </div>
                            <div className="mt-2 flex">
                                <input
                                    // ref={ref}
                                    // id={id}
                                    // name={name}
                                    placeholder="Enter Your Contact Number"
                                    // placeholder={placeholder}

                                    type="text"
                                    className={`block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium `}
                                // className={`block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium  ${hasError || isTouch || subError
                                //     ? "border-red-600 bg-red-50"
                                //     : "focus:border-[#070756]"
                                //     }`}
                                // onChange={onChangeHandler}
                                // onKeyDown={onKeyDownHandler}
                                // value={enterValue}
                                // onBlur={() => {
                                //     onBlurHandler();
                                //     setIsTouch("");
                                // }}
                                />
                                {/* {(handleToggleParam === "password" ||
                                handleToggleParam === "con-password") && (
                                    <span
                                        className="flex justify-around items-center cursor-pointer "
                                        onClick={() => handleToggle(handleToggleParam)}
                                    >
                                        <EyeIcon type={type} />
                                    </span>
                                )} */}
                            </div>
                            {/* {hasError && (
                            <div className="flex ml-[0.5px]">
                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                    {errorMessage}
                                </p>
                            </div>
                        )} */}
                            {/* {isTouch && (
                            <div className="flex ml-[0.5px]">
                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                    {isTouch}
                                </p>
                            </div>
                        )} */}
                        </div>
                    </div>
                    <div className="my-4 grid grid-cols-1 ">
                        <div >
                            {/* <div className="flex items-center justify-between">
                            <label
                                htmlFor="password"
                                className="block text-sm font-bold leading-6 text-gray-900"
                            >
                                 <span className="text-red-600 font-bold">*</span>
                            </label>
                        </div> */}
                            <div className="mt-2 flex">
                                <textarea
                                    // ref={ref}
                                    // id={id}
                                    // name={name}
                                    rows={6}
                                    placeholder="Enter Your Full Name"
                                    // placeholder={placeholder}

                                    type="text"
                                    className={`block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium `}
                                // className={`block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium  ${hasError || isTouch || subError
                                //     ? "border-red-600 bg-red-50"
                                //     : "focus:border-[#070756]"
                                //     }`}
                                // onChange={onChangeHandler}
                                // onKeyDown={onKeyDownHandler}
                                // value={enterValue}
                                // onBlur={() => {
                                //     onBlurHandler();
                                //     setIsTouch("");
                                // }}
                                />
                                {/* {(handleToggleParam === "password" ||
                                handleToggleParam === "con-password") && (
                                    <span
                                        className="flex justify-around items-center cursor-pointer "
                                        onClick={() => handleToggle(handleToggleParam)}
                                    >
                                        <EyeIcon type={type} />
                                    </span>
                                )} */}
                            </div>
                            {/* {hasError && (
                            <div className="flex ml-[0.5px]">
                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                    {errorMessage}
                                </p>
                            </div>
                        )} */}
                            {/* {isTouch && (
                            <div className="flex ml-[0.5px]">
                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                    {isTouch}
                                </p>
                            </div>
                        )} */}
                        </div>


                    </div>
                    <div className="my-4 grid grid-cols-1 ">

                        <div className="">
                            <button
                                type="submit"
                                // disabled={isLoading}
                                className={`bg-[#070756] hover:bg-[#00009C] duration-700 w-full rounded-md  px-2 py-2 text-center text-[22px] font-medium text-white shadow-sm focus:outline-none focus:bg-[#00009C] `}
                            >
                                Submit
                            </button>
                        </div>


                    </div>
                </form>
            </div>
            <div className="mb-20">
                <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3665.396777101346!2d77.36856951495469!3d23.265028268126738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x397c67e51acedc03%3A0x115f2824b3fb9509!2s7979%2BGM3%20Lavanya%20Gurukul%20School%20(L.G.S)%2C%20VIP%20Road%2C%20Bhopal%2C%20Madhya%20Pradesh%20462001!3m2!1d23.263771!2d77.36916599999999!5e0!3m2!1sen!2sin!4v1709634304705!5m2!1sen!2sin" className="w-[100%] h-[400px]" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
}
