import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { fetch, scrollToTop } from "../../service/utils";
import toast from "react-hot-toast";

function HrPolicy() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState("");

  const [hrPolicy, setHrPolicy] = useState("");
  const [hrPolicyFile, setHrPolicyFile] = useState("");

  const jobId = useParams();
  const job_id = jobId.id;

  const submitHandler = async (e) => {
    e.preventDefault();
    setNetworkError("");
    setIsLoading(true);

    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const formData = new FormData();
      formData.append("hr_policy_text", hrPolicy);
      formData.append("job_id", job_id);

      if (hrPolicyFile) {
        formData.append("hr_policy_file", hrPolicyFile);
      }
      const response = await fetch(
        `/enabler/create-hrpolicy`,
        "post",
        formData,
        headers
      );
      if (response.data.ok === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
        return false;
      }
      setIsLoading(false);
      navigate(`/technohub/enabler/update-job/${job_id}`);
      response.data && toast.success(response.data.message);
      setNetworkError("");
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
        toast.error("Something Went Wrong. Please Try Again Later.");
      }
    }
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mx-2 text-center text-2xl font-bold leading-9 tracking-tight text-[#070756]">
            HR Policy
          </h2>
        </div>

        <div className="mt-6 sm:mx-auto md:w-[55%]">
          <form className="space-y-6 " onSubmit={submitHandler}>
            <div className="grid grid-cols-1 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:gap-x-8 ">
              <div>
                <label className="block text-sm font-bold leading-6 text-gray-900"></label>

                <div className="mt-2">
                  <TextArea
                    id="address"
                    name="address"
                    type="text"
                    rows={3}
                    placeholder="Enter Your Response"
                    defaultValue={hrPolicy}
                    onChange={(e) => setHrPolicy(e.target.value)}
                    className="block w-full rounded-md border-0  px-2 py-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:gap-x-8 ">
              <div>
                <div className="mt-2">
                  <input
                    id="hrfile"
                    name="hrfile"
                    type="file"
                    autoComplete="hrfile"
                    defaultValue={hrPolicyFile}
                    onChange={(e) => setHrPolicyFile(e.target.files[0])}
                    className="block w-full rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 max-[640px]:grid-cols-1 mt-4">
              <div className="mx-12 max-[640px]:my-4">
                <Link to="technohub/enabler/post-job">
                  <button className="flex w-full justify-center rounded-md bg-red-500  px-2 py-2 text-[16px]  font-medium leading-6 text-white shadow-sm duration-700 hover:bg-[#070756] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Back Home
                  </button>
                </Link>
              </div>
              <div className="mx-12">
                <button
                  type="submit"
                  className={`bg-[#070756] hover:bg-[#00009C] duration-700 w-full rounded-md  px-2 py-2 text-center text-[16px] font-medium text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  ${
                    isLoading ? "cursor-not-allowed" : ""
                  }`}
                >
                  {isLoading ? "Loading..." : "Update Now"}
                </button>
              </div>
            </div>
          </form>
          <div className="text-red-600 text-sm italic text-center pt-5">
            {networkError && (
              <strong className="text-red-600 text-sm italic text-center">
                *{networkError}*
              </strong>
            )}
            {isLoading && (
              <strong className="text-blue-800 text-sm text-center">
                Your Profile Is Being Updated...
              </strong>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default HrPolicy;
