import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import programReducer from "./features/programSlice";
import departmentReducer from "./features/departmentSlice";
import enablerReducer from "./features/enablerSlice";
import leanerReducer from "./features/learnerSlice";
import selectedDepattIdReducer from "./features/selectedDepattIdSlice";
import deptLoadingSlice from "./features/deptLoadingSlice";
import orderApplyRducer from "./features/orderSlice";
import myProgramsReducer from "./features/myProgramsSlice";
import desiredProgramsReducer from "./features/desiredProgramsSlice";
import allPostedJobReducer from "./features/enablerJobSlice";
import allAssignBatchStudentDataReducer from "./features/assigned-batches-students";
export default configureStore({
  reducer: {
    user: userReducer,
    programs: programReducer,
    department: departmentReducer,
    allEnablers: enablerReducer,
    allLearners: leanerReducer,
    depttId: selectedDepattIdReducer,
    deptLoading: deptLoadingSlice,
    orderApply: orderApplyRducer,
    myPrograms: myProgramsReducer,
    desiredPrograms: desiredProgramsReducer,
    allPostedJob: allPostedJobReducer,
    AssignBatchStudentData: allAssignBatchStudentDataReducer,
  },
});
