import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

export default function FacultyModal({
  facultyModalOpen,
  setFacultyModalOpen,
}) {
  return (
    <Transition.Root show={facultyModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        // initialFocus={cancelButtonRef}
        onClose={setFacultyModalOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className=" w-[25%]  max-[315px]:w-[90%] max-[440px]:w-[70%] max-[640px]:w-[50%] sm:w-[40%] md:w-[30%] lg:w-[25%] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 "
                style={{ marginTop: "-20px" }}
              >
                <div className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Link to="/">
                    <button
                      type="button"
                      className="mt-2 inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold duration-700 text-[#070756]   sm:mt-0 sm:w-auto max-[640px]:w-[25%] max-[460px]:w-[25%] max-[270px]:w-[30%]"
                      onClick={() => setFacultyModalOpen(false)}
                    // ref={cancelButtonRef}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </Link>
                </div>
                <h1 className="text-center font-bold text-2xl text-[#070756]">
                  Login Departments
                </h1>
                <div className="bg-white px-4 pb-4  sm:p-6 sm:pb-4">
                  <div className=" text-center">
                    <div className="grid grid-cols-1 gap-x-4 max-[640px]:grid-cols-1">
                      <div>
                        <div>
                          <Link
                            to="/technohub/learner/signin"
                            className="mx-0"
                            onClick={() => localStorage.setItem("deptt_id", 3)}
                          >
                            <button
                              className="hover:bg-[#00009C] bg-[#070756] mt-4 w-40 rounded-md  px-3 py-2 text-center text-lg font-medium duration-700 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                              onClick={() => setFacultyModalOpen(false)}
                            >
                              Learner Login
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div>
                        <div className="">
                          <Link
                            to="/technohub/enabler/login"
                            className="mx-0"
                            onClick={() => localStorage.setItem("deptt_id", 3)}
                          >
                            <button
                              className="hover:bg-[#00009C] bg-[#070756] mt-4 w-40 rounded-md  px-3 py-2 text-center text-lg font-medium duration-700 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                              onClick={() => setFacultyModalOpen(false)}
                            >
                              Enabler Login
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div>
                        <div>
                          <Link to="/tutor/login" className="mx-0">
                            <button
                              className="hover:bg-[#00009C] bg-[#070756] mt-4 w-40 rounded-md  px-3 py-2 text-center text-lg font-medium duration-700 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                              onClick={() => setFacultyModalOpen(false)}
                            >
                              Tutor Login
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className=" mb-8">
                        <div className="">
                          <Link to="/admin/login" className="mx-0 ">
                            <button
                              className="hover:bg-[#00009C] bg-[#070756] mt-4 w-40 rounded-md  px-3 py-2 text-center text-lg font-medium duration-700 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                              onClick={() => setFacultyModalOpen(false)}
                            >
                              Admin Login
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
