import React, { useState, useEffect } from "react";
import { emailreg } from "../../validations/validation";
import { Link, useNavigate } from "react-router-dom";
import { useInput } from "../../hook/input-hook";
import { useDispatch } from "react-redux";
import images from "../../service/imageImporter";
import { fetch } from "../../service/utils";
import { setUser } from "../../redux/features/userSlice";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import Input from "../input/Input.component";

// =======================normal form start===========================
const isName = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Your Name" };
  } else {
    return { validated: true, message: "" };
  }
};

const isEmail = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Your Email" };
  } else if (!emailreg.test(value)) {
    return { validated: false, message: "Your Email Is Invalid" };
  } else {
    return { validated: true, message: "" };
  }
};

const isPassword = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Your Password" };
  } else if (value.length < 6) {
    return {
      validated: false,
      message: "Password Should Contain at least 6 Characters!",
    };
  } else {
    return { validated: true, message: "" };
  }
};
const isConfirmPassword = (value) => {
  if (value.trim() === "") {
    return {
      validated: false,
      message: "Please Confirm Your Password",
    };
  } else {
    return { validated: true, message: "" };
  }
};

// =======================normal form end===============================

// =======================organisation form start=======================
const isOrganizationName = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Organisation Name" };
  } else {
    return { validated: true, message: "" };
  }
};
const isContacPName = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Contact Person Name" };
  } else {
    return { validated: true, message: "" };
  }
};

const isContacPDesignation = (value) => {
  if (value.trim() === "") {
    return {
      validated: false,
      message: "Please Enter Contact Person Designation",
    };
  } else {
    return { validated: true, message: "" };
  }
};

const isContacPEmail = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Contact Person Email" };
  } else if (!emailreg.test(value)) {
    return { validated: false, message: "Your Email Is Invalid" };
  } else {
    return { validated: true, message: "" };
  }
};
// =======================organisation form end=========================
function LearnerSignup() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [emailError, setEmailkError] = useState("");

  const [organisationRadio, setOrganisationRadio] = useState(true);
  const [individualRadio, setIndividualRadio] = useState(false);
  const [proposerType, setProposerType] = useState("Organisation");

  const [organisationNameTouch, setOrganisationNameTouch] = useState("");
  const [contactPersonNameTouch, setContactPersonNameTouch] = useState("");
  const [contactPersonDesigTouch, setContactPersonDesigTouch] = useState("");
  const [contactPersonEmailTouch, setContactPersonEmailTouch] = useState("");
  // ====================================================================================
  const [nameTouch, setNameTouch] = useState("");
  const [EmailTouch, setEmailTouch] = useState("");
  const [passwordTouch, setPasswordTouch] = useState("");
  const [cPasswordTouch, setCPasswordTouch] = useState("");

  const {
    hasError: hasErrorName,
    enterValue: enterValueName,
    onChangeHandler: onChangeHandlerName,
    reset: resetName,
    message: NameMessage,
    isTouch: isTouchName,
    onBlurHandler: onBlurHandlerName,
  } = useInput(isName, setNetworkError, setNameTouch);

  const {
    hasError: hasErrorEmail,
    enterValue: enterValueEmail,
    onChangeHandler: onChangeHandlerEmail,
    reset: resetEmail,
    message: emailMessage,
    isTouch: isTouchEmail,
    onBlurHandler: onBlurHandlerEmail,
  } = useInput(isEmail, setNetworkError, setEmailTouch);

  const {
    hasError: hasErrorPassword,
    enterValue: enterValuePassword,
    message: passwordMessage,
    onChangeHandler: onChangeHandlerPassword,
    reset: resetPassword,
    isTouch: isTouchPassword,
    onBlurHandler: onBlurHandlerPwd,
  } = useInput(isPassword, setNetworkError, setPasswordTouch);

  const {
    hasError: hasErrorConfirmPassword,
    enterValue: enterValueConfirmPassword,
    message: ConfirmpasswordMessage,
    onChangeHandler: onChangeHandlerConfirmPassword,
    reset: resetConfirmPassword,
    isTouch: isTouchConfirmPassword,
    onBlurHandler: onBlurHandlerConPwd,
  } = useInput(isConfirmPassword, setNetworkError, setCPasswordTouch);
  // ============================================================================
  const {
    hasError: hasErrorOrganizationName,
    enterValue: enterValueOrganizationName,
    onChangeHandler: onChangeHandlerOrganizationName,
    reset: resetOrganizationName,
    message: OrganizationNameMessage,
    isTouch: isTouchOrganizationName,
    onBlurHandler: onBlurHandlerOrganizationName,
  } = useInput(isOrganizationName, setNetworkError, setOrganisationNameTouch);

  const {
    hasError: hasErrorContacPName,
    enterValue: enterValueContacPName,
    onChangeHandler: onChangeHandlerContacPName,
    reset: resetContacPName,
    message: ContacPNameMessage,
    isTouch: isTouchContacPName,
    onBlurHandler: onBlurHandlerContacPName,
  } = useInput(isContacPName, setNetworkError, setContactPersonNameTouch);

  const {
    hasError: hasErrorContacPDesignation,
    enterValue: enterValueContacPDesignation,
    onChangeHandler: onChangeHandlerContacPDesignation,
    reset: resetContacPDesignation,
    message: ContacPDesignationMessage,
    isTouch: isTouchContacPDesignation,
    onBlurHandler: onBlurHandlerContacPDesignation,
  } = useInput(
    isContacPDesignation,
    setNetworkError,
    setContactPersonDesigTouch
  );

  const {
    hasError: hasErrorContacPEmail,
    enterValue: enterValueContacPEmail,
    onChangeHandler: onChangeHandlerContacPEmail,
    reset: resetContacPEmail,
    message: ContacPEmailMessage,
    isTouch: isTouchContacPEmail,
    onBlurHandler: onBlurHandlerContacPEmail,
  } = useInput(isContacPEmail, setNetworkError, setContactPersonEmailTouch);

  const [subError, setSubError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setSubError("");
    setNameTouch("");
    setEmailTouch("");
    setPasswordTouch("");
    setCPasswordTouch("");
  }, [
    enterValueEmail,
    enterValuePassword,
    enterValueName,
    enterValueConfirmPassword,
  ]);

  useEffect(() => {
    setSubError("");
    setOrganisationNameTouch("");
    setContactPersonNameTouch("");
    setContactPersonDesigTouch("");
    setContactPersonEmailTouch("");
  }, [
    enterValueOrganizationName,
    enterValueContacPName,
    enterValueContacPDesignation,
    enterValueContacPEmail,
  ]);

  // *phone start
  const [contMobile, setContMobile] = useState("");
  const [nationalNumber, setNationalNumber] = useState("");
  const [mobileError, setMobileError] = useState("");

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setContMobile(value);
    const phoneNumber = parsePhoneNumberFromString(formattedValue, country);
    phoneNumber && setNationalNumber(phoneNumber.nationalNumber);
    if (phoneNumber && phoneNumber.isValid()) {
      setMobileError("");
    } else {
      setMobileError("Invalid phone number!");
    }
  };
  // *phone end

  // *contact person phone start
  const [contactPMobile, setContactPMobile] = useState("");
  const [contactNationalNumber, setContactNationalNumber] = useState("");
  const [contactmobileError, setContactMobileError] = useState("");

  const handleContactPhoneChange = (value, country, e, formattedValue) => {
    setContactPMobile(value);
    const phoneNumber = parsePhoneNumberFromString(formattedValue, country);
    phoneNumber && setContactNationalNumber(phoneNumber.nationalNumber);
    if (phoneNumber && phoneNumber.isValid()) {
      setContactMobileError("");
    } else {
      setContactMobileError("Invalid phone number!");
    }
  };
  // *contact person phone end
  const submitHandler = async (e) => {
    e.preventDefault();
    setNetworkError(false);
    setIsLoading(true);
    if (
      hasErrorName === true ||
      hasErrorEmail === true ||
      hasErrorPassword === true ||
      hasErrorConfirmPassword === true ||
      mobileError
    ) {
      setSubError("fields");
      setIsLoading(false);
      return false;
    } else if (
      !isTouchName ||
      !isTouchEmail ||
      !isTouchPassword ||
      !isTouchConfirmPassword
    ) {
      if (!isTouchName) {
        setNameTouch("Please Enter Your Full Name");
      }
      if (!isTouchEmail) {
        setEmailTouch("Please Enter Your Email");
      }
      if (!isTouchPassword) {
        setPasswordTouch("Please Enter Your Password");
      }
      if (!isTouchConfirmPassword) {
        setCPasswordTouch("Please Confirm Your Password");
      }
      if (nationalNumber === "") {
        setMobileError("Please Enter Your Mobile Number.");
      }
      setSubError("fields");
      setIsLoading(false);
      return false;
    } else if (nationalNumber === "") {
      setMobileError("Please Enter Your Mobile Number.");
      setIsLoading(false);
      return false;
    } else if (enterValuePassword !== enterValueConfirmPassword) {
      setIsLoading(false);
      setSubError("passwords");
      return false;
    }
    if (organisationRadio === true) {
      if (
        hasErrorOrganizationName === true ||
        hasErrorContacPName === true ||
        hasErrorContacPDesignation === true ||
        hasErrorContacPEmail === true ||
        contactmobileError
      ) {
        setSubError("fields");
        setIsLoading(false);

        return false;
      } else if (
        !isTouchOrganizationName ||
        !isTouchContacPName ||
        !isTouchContacPDesignation ||
        !isTouchContacPEmail
      ) {
        if (!isTouchOrganizationName) {
          setOrganisationNameTouch("Please Enter Organisation Name");
        }
        if (!isTouchContacPName) {
          setContactPersonNameTouch("Please Enter Contact Person Name");
        }
        if (!isTouchContacPDesignation) {
          setContactPersonDesigTouch("Please Enter Contact Person Designation");
        }
        if (!isTouchContacPEmail) {
          setContactPersonEmailTouch("Please Enter Contact Person Email");
        }

        setSubError("fields");
        setIsLoading(false);
        return false;
      } else if (contactNationalNumber === "") {
        setContactMobileError("Please Enter Contact Person Mobile Number.");
        setIsLoading(false);
        return false;
      } else if (enterValueContacPEmail === enterValueEmail) {
        setNetworkError("Learner email and proposer email cannot be same.");
        setIsLoading(false);
        return false;
      }
    } else {
      setSubError("fields");
    }

    try {
      const body = {
        user_name: enterValueName,
        user_email: enterValueEmail,
        user_mobile_no: contMobile,
        user_pwd: enterValuePassword,
        confirm_password: enterValueConfirmPassword,
        proposer_type: proposerType,
        org_name: enterValueOrganizationName,
        contact_person_name: enterValueContacPName,
        contact_person_designation: enterValueContacPDesignation,
        contact_person_email: enterValueContacPEmail,
        contact_person_mobile: contactPMobile,
      };
      // if (organisationRadio === true) {
      //   const body = {
      //     user_name: enterValueName,
      //     user_email: enterValueEmail,
      //     user_mobile_no: contMobile,
      //     user_pwd: enterValuePassword,
      //     confirm_password: enterValueConfirmPassword,
      //     proposer_type: "Organisation",
      //     org_name: enterValueOrganizationName,
      //     contact_person_name: enterValueContacPName,
      //     contact_person_designation: enterValueContacPDesignation,
      //     contact_person_email: enterValueContacPEmail,
      //     contact_person_mobile: contMobile,
      //   };
      // } else {
      //   const body = {
      //     user_name: enterValueName,
      //     user_email: enterValueEmail,
      //     user_mobile_no: contMobile,
      //     user_pwd: enterValuePassword,
      //     confirm_password: enterValueConfirmPassword,
      //     proposer_type: "Individual",
      //   };
      // }
      const response = await fetch("/learner/add", "post", body);
      setIsLoading(false);
      if (response && response.data.ok === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
        toast.error(response.data.message);
        return false;
      }

      const userData = {
        user_id: response.data.user.user_id,
        role_id: response.data.user.role_id,
        user_name: response.data.user.user_name,
        user_email: response.data.user.user_email,
        user_mobile_no: response.data.user.user_mobile_no,
        user_address: response.data.user.user_address,
        participant_photo: response.data.user.participant_photo
          ? response.data.user.participant_photo
          : null,
      };
      dispatch(setUser(userData));
      localStorage.setItem("lgs_token", response.data.token);
      localStorage.setItem("desired_courses", "null");
      resetEmail();
      resetPassword();
      resetConfirmPassword();
      resetName();
      resetContacPDesignation();
      resetContacPName();
      resetContacPEmail();
      navigate("/technohub/learner/all-programs");
      toast.success("Registered Successfully!");
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
        toast.error("Something Went Wrong. Please Try Again Later.");
      }
    }
  };

  const [type, setType] = useState("password");
  const [type2, setType2] = useState("password");

  const handleToggle = (id) => {
    if (id === "password") {
      if (type === "password") {
        setType("text");
      } else {
        setType("password");
      }
    } else {
      if (type2 === "password") {
        setType2("text");
      } else {
        setType2("password");
      }
    }
  };

  const onKeyDownHandlerConfPwd = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitHandler(e);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-16 w-auto"
            src={images[`Technohub.jpeg`]}
            alt="Your Company"
          />
          <h2 className="mx-2 text-center text-2xl font-bold leading-9 tracking-tight text-[#070756]">
            Learner Sign Up
          </h2>
        </div>

        <div className="mt-6 mx-auto max-[640px]:w-[80%] sm:w-[70%] md:w-[70%] lg:w-[60%]">
          <form className="space-y-6 " onSubmit={submitHandler}>
            <div className="grid grid-cols-1 gap-x-10 gap-y-2 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2  ">
              <div>
                <Input
                  type="text"
                  label="Full Name"
                  placeholder="Enter Your Full Name"
                  id="name"
                  name="name"
                  enterValue={enterValueName}
                  onChangeHandler={onChangeHandlerName}
                  onKeyDownHandler={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  hasError={hasErrorName}
                  errorMessage={NameMessage}
                  isTouch={nameTouch}
                  setIsTouch={setNameTouch}
                  onBlurHandler={onBlurHandlerName}
                />
              </div>
              <div>
                <label
                  htmlFor="mobile"
                  className="block text-sm font-bold leading-6 text-gray-900"
                >
                  Mobile <span className="text-red-600 font-bold">*</span>
                </label>
                <div className="mt-2">
                  <PhoneInput
                    inputStyle={{ width: "100%", height: "43px" }}
                    eEditable={false}
                    value={contMobile}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    country={"in"}
                    onChange={handlePhoneChange}
                  />
                </div>
                {mobileError && (
                  <div className="flex ml-[0.5px]">
                    <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                    <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                      {mobileError}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-x-10 gap-y-2 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2  ">
              <div>
                <Input
                  type={type}
                  label="Password"
                  placeholder="Enter Your Password"
                  id="password"
                  name="password"
                  enterValue={enterValuePassword}
                  onChangeHandler={onChangeHandlerPassword}
                  onKeyDownHandler={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  hasError={hasErrorPassword}
                  errorMessage={passwordMessage}
                  isTouch={passwordTouch}
                  setIsTouch={setPasswordTouch}
                  onBlurHandler={onBlurHandlerPwd}
                  handleToggle={handleToggle}
                  handleToggleParam="password"
                />
              </div>

              <div>
                <Input
                  type={type2}
                  label="Confirm Password"
                  placeholder="Confirm Your Password"
                  id="con-password"
                  name="con-password"
                  enterValue={enterValueConfirmPassword}
                  onChangeHandler={onChangeHandlerConfirmPassword}
                  onKeyDownHandler={onKeyDownHandlerConfPwd}
                  hasError={hasErrorConfirmPassword}
                  errorMessage={ConfirmpasswordMessage}
                  isTouch={cPasswordTouch}
                  setIsTouch={setCPasswordTouch}
                  onBlurHandler={onBlurHandlerConPwd}
                  handleToggle={handleToggle}
                  handleToggleParam="con-password"
                  subError={subError === "passwords"}
                />
                {subError === "passwords" && (
                  <div className="flex ml-[0.5px]">
                    <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                    <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                      Passwords Do Not Match
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-10 max-[640px]:grid-cols-1">
              <Input
                type="text"
                label="Email"
                placeholder="Enter Your Email"
                id="email"
                name="email"
                enterValue={enterValueEmail}
                onChangeHandler={onChangeHandlerEmail}
                onKeyDownHandler={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                hasError={hasErrorEmail}
                errorMessage={emailMessage}
                isTouch={EmailTouch}
                setIsTouch={setEmailTouch}
                onBlurHandler={onBlurHandlerEmail}
              />
            </div>

            <div className="grid grid-cols-2 gap-x-10 max-[640px]:grid-cols-1">
              <div className=" max-[640px]:my-4">
                <label className="block text-sm font-bold leading-6 text-gray-900 float-left">
                  Organisation <span className="text-red-600 font-bold">*</span>
                </label>
                <input
                  type="radio"
                  id="organisationRadio"
                  name="organisationRadio"
                  className="w-[17px] h-[17px] mx-2 mt-1"
                  checked={organisationRadio}
                  onClick={() => {
                    setProposerType("Organisation");
                    setOrganisationRadio(true);
                    setIndividualRadio(false);
                  }}
                />
              </div>
              <div className="">
                <label className="block text-sm font-bold leading-6 text-gray-900 float-left">
                  Individual <span className="text-red-600 font-bold">*</span>
                </label>
                <input
                  type="radio"
                  disabled
                  id="organisationRadio"
                  name="organisationRadio"
                  className="w-[17px] h-[17px] mx-2 mt-1"
                  onClick={() => {
                    setProposerType("individual");
                    setIndividualRadio(true);
                    setOrganisationRadio(false);
                  }}
                />
              </div>
            </div>
            {/* ===========================Organisation form===================================== */}
            {organisationRadio === true ? (
              <div>
                <div className="grid grid-cols-1 gap-x-10 gap-y-2 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2  ">
                  <div>
                    <Input
                      type="text"
                      label="Organisation Name"
                      placeholder="Enter Organisation Name"
                      id="organisation_name"
                      name="organisation_name"
                      enterValue={enterValueOrganizationName}
                      onChangeHandler={onChangeHandlerOrganizationName}
                      onKeyDownHandler={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                      hasError={hasErrorOrganizationName}
                      errorMessage={OrganizationNameMessage}
                      isTouch={organisationNameTouch}
                      setIsTouch={setOrganisationNameTouch}
                      onBlurHandler={onBlurHandlerOrganizationName}
                    />
                  </div>
                  <div>
                    <Input
                      type="text"
                      label="Contact Person Name"
                      placeholder="Enter Contact Person Name"
                      id="cp_name"
                      name="cp_name"
                      enterValue={enterValueContacPName}
                      onChangeHandler={onChangeHandlerContacPName}
                      onKeyDownHandler={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                      hasError={hasErrorContacPName}
                      errorMessage={ContacPNameMessage}
                      isTouch={contactPersonNameTouch}
                      setIsTouch={setContactPersonNameTouch}
                      onBlurHandler={onBlurHandlerContacPName}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-10 gap-y-2 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2  ">
                  <div className="mt-4">
                    <Input
                      type="text"
                      label="Contact Person Designation"
                      placeholder="Enter Contact Person Designation"
                      id="cp_designation"
                      name="cp_designation"
                      enterValue={enterValueContacPDesignation}
                      onChangeHandler={onChangeHandlerContacPDesignation}
                      onKeyDownHandler={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                      hasError={hasErrorContacPDesignation}
                      errorMessage={ContacPDesignationMessage}
                      isTouch={contactPersonDesigTouch}
                      setIsTouch={setContactPersonDesigTouch}
                      onBlurHandler={onBlurHandlerContacPDesignation}
                    />
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="mobile"
                      className="block text-sm font-bold leading-6 text-gray-900"
                    >
                      Contact Person Mobile{" "}
                      <span className="text-red-600 font-bold">*</span>
                    </label>
                    <div className="mt-2">
                      <PhoneInput
                        inputStyle={{ width: "100%", height: "43px" }}
                        eEditable={false}
                        value={contactPMobile}
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        country={"in"}
                        onChange={handleContactPhoneChange}
                      />
                    </div>
                    {contactmobileError && (
                      <div className="flex ml-[0.5px]">
                        <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                        <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                          {contactmobileError}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-x-10 max-[640px]:grid-cols-1 mt-4">
                  <Input
                    type="text"
                    label="Contact Person Email"
                    placeholder="Enter Contact Person Email"
                    id="cp_email"
                    name="cp_email"
                    enterValue={enterValueContacPEmail}
                    onChangeHandler={onChangeHandlerContacPEmail}
                    onKeyDownHandler={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    hasError={hasErrorContacPEmail}
                    errorMessage={ContacPEmailMessage}
                    isTouch={contactPersonEmailTouch}
                    setIsTouch={setContactPersonEmailTouch}
                    onBlurHandler={onBlurHandlerContacPEmail}
                  />
                </div>
              </div>
            ) : organisationRadio === false &&
              individualRadio === false ? null : (
              <div className="w-[100%]">
                <h3 className="text-red-600 text-base text-center">
                  Soory! Indivisual Form Not Availabe in This Time
                </h3>
              </div>
            )}
            {emailError && (
              <div className="flex ml-[0.5px]">
                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                <p className="text-red-600 text-xs leading-16 mt-[3.5px]"></p>
              </div>
            )}
            <div
              className="grid grid-cols-2 gap-x-10 max-[640px]:grid-cols-1"
              style={{ marginTop: "5%" }}
            >
              <div className=" max-[640px]:my-4">
                <button
                  onClick={() => navigate("/")}
                  className="flex w-full justify-center rounded-md bg-red-500  px-2 py-2 text-[16px]  font-medium leading-6 text-white shadow-sm duration-700 hover:bg-[#070756] focus:outline-none focus:bg-[#070756]"
                >
                  Back Home
                </button>
              </div>
              <div className="">
                <button
                  type="submit"
                  className={`bg-[#070756] hover:bg-[#00009C] duration-700 w-full rounded-md  px-2 py-2 text-center text-[16px] font-medium text-white shadow-sm focus:outline-none focus:bg-[#00009C] ${isLoading ? "cursor-not-allowed" : ""
                    }`}
                >
                  {isLoading ? "Loading..." : "Sign Up"}
                </button>
              </div>
            </div>
          </form>
          <div className="text-red-500 text-sm  text-center pt-5">
            {subError === "fields" && (
              <strong className="text-red-500 text-sm  text-center">
                Please Fill All TheFields Appropriately.
              </strong>
            )}
            {networkError && (
              <strong className="text-red-500 text-sm  text-center">
                {networkError}
              </strong>
            )}
            {isLoading && (
              <strong className="text-blue-800 text-sm text-center">
                Please Wait, Signing Up...
              </strong>
            )}
          </div>
          <div className="flex items-center justify-between mt-6">
            {" "}
            <div className="text-sm">
              <Link
                to="/technohub/learner/signin"
                className="font-semibold text-[#00009C] hover:text-[#070756]"
              >
                Already Have an Account? Sign In!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LearnerSignup;
