import React, { useEffect, useState } from "react";
import { fetch, scrollToTop } from "../service/utils";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";

export default function ApprovalMail() {
  useEffect(() => {
    scrollToTop();
  }, []);

  const { learner_id, proposer_id, enc } = useParams();
  let l_id = learner_id;
  let p_id = proposer_id;

  const navigate = useNavigate();
  const [networkError, setNetworkError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const sendApprovalSubmit = async () => {
    setLoading(true);
    setNetworkError("");
    setSuccessMsg("");
    try {
      const response = await fetch(
        `/proposer/confirm/${l_id}/${p_id}?token=${enc}`,
        "post"
      );
      if (response.ok === false) {
        setLoading(false);
        setNetworkError(response.data.message);
      } else {
        setLoading(false);
        setSuccessMsg(response.data.message);
        toast.success("Successfully approved applicant.");
        navigate("/");
      }
    } catch (err) {
      setSuccessMsg("");
      setLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    }
  };

  const [infoError, setInfoError] = useState("");
  const [infoLoading, setInfoLoading] = useState(false);
  const [learnerInfo, setLearnerInfo] = useState({});

  const getLearnerInfo = async () => {
    setInfoLoading(true);
    setInfoError("");
    try {
      const response = await fetch(`/proposer/get-learner-info`, "post", {
        applicant_id: learner_id,
      });
      if (response.ok === false) {
        setInfoLoading(false);
        setInfoError(response.data.message);
      } else {
        setLearnerInfo(response.data.details);
        setInfoLoading(false);
      }
    } catch (err) {
      setInfoLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setInfoError(err.response.data.message);
      } else {
        setInfoError("Something Went Wrong. Please Try Again Later.");
      }
    }
  };

  useEffect(() => {
    getLearnerInfo();
  }, []);

  return (
    <>
      <div className="mx-auto max-w-3xl max-[662px]:text-center md:text-center lg:text-center">
        <h2 className="text-2xl mt-6 font-bold tracking-tight text-gray-900 sm:text-3xl sm:text-center">
          Applicant Approval Request
        </h2>
      </div>

      {infoLoading === true && (
        <>
          <div className="flex justify-center items-center mt-40 mb-44">
            <BeatLoader color="#070756" loading={true} size={15} />
            <br />
          </div>
        </>
      )}

      {infoError && infoLoading === false && (
        <div className="text-center mb-80">
          <strong className="text-red-600">{infoError}</strong>
        </div>
      )}

      {!infoLoading && !infoError && (
        <>
          <div className="-mx-4 flex flex-wrap p-8 h justify-center">
            {/* {allottedBatch && allottedBatch?.batch_name !== null && ( */}
            <div className="w-full px-4 md:w-1/2 lg:w-3/3">
              <div
                className={`mb-9 rounded-xl py-8 px-7 shadow-md shadow-[#00009C] transition-all hover:shadow-lg hover:shadow-[#023e8a]  sm:p-9 lg:px-6 xl:px-9 `}
              >
                <div className="mx-auto mb-0 ">
                  {/* <MailOpenOutline className="w-12 h-12 text-[#070756] float-left" /> */}
                  {/* <h3 className=" mt-2 text-xl font-bold text-[#00009C]   sm:text-2xl lg:text-xl xl:text-2xl text-center">
                  Approval Request
                </h3> */}
                </div>
                <div className="">
                  <div className="text-base ">
                    <ul className="text-base text-start  font-medium text-body-color  ml-6">
                      <li className=" my-1 text-[#333] ">
                        <p className="my-2">
                          {learnerInfo && learnerInfo[0]?.user_name}, an
                          applicant has recently registered on the LGS portal,
                          citing you as their proposer.{" "}
                        </p>
                        <p className="mb-4">
                          To facilitate the application process, we kindly seek
                          your approval.
                        </p>
                        <p className="mb-4">
                          Please take a moment to click the button below to
                          approve the request.
                        </p>
                        <p className="mb-4">Thank you for your cooperation.</p>
                      </li>
                    </ul>
                    <div className="w-full text-center mt-8">
                      <button
                        onClick={sendApprovalSubmit}
                        disabled={successMsg}
                        className={` text-white px-4 py-2 text-base font-medium rounded-md mb-3 ${
                          successMsg
                            ? "cursor-not-allowed bg-[#327cb9]"
                            : "bg-[#00009C]"
                        }`}
                      >
                        Approve Now
                      </button>
                      {networkError && (
                        <div className="w-[100%] text-center ">
                          <span className="text-red-700 text-sm font-semibold">
                            {networkError}
                          </span>
                        </div>
                      )}
                      {loading && (
                        <div className="w-[100%] text-center ">
                          <span className="text-blue-700 text-sm font-semibold">
                            Approving aaplicant...
                          </span>
                        </div>
                      )}
                      {successMsg && (
                        <div className="w-[100%] text-center ">
                          <span className="text-green-700 text-sm font-semibold">
                            {successMsg}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
          </div>
        </>
      )}
    </>
  );
}
