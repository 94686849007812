import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { DesktopOutlined, PieChartOutlined } from "@ant-design/icons";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAssignBatchesStudent } from "../../redux/features/assigned-batches-students";

const { Sider } = Layout;

function getItem(label, key, path, icon, children) {
  return {
    key,
    icon,
    children,
    label,
    path,
  };
}

const TutorSideBar = () => {
  const assignBatches = useSelector(
    (state) => state.AssignBatchStudentData.assignBatchesStudent
  );
  const items = [
    getItem("Dashboard", "1", "/tutor/dashboard/home", <PieChartOutlined />),
    getItem(
      "Batches",
      "2",
      null,
      <DesktopOutlined />,
      assignBatches &&
        assignBatches?.map((bth, ind) =>
          getItem(
            bth.batch_name,
            ind + 3,
            `/tutor/dashboard/batches/${bth.m_batch_id}`
          )
        )
    ),
  ];

  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();

  const getSelectedKeys = () => {
    const pathname = location.pathname;
    const selectedItem = items.find((item) => item.path === pathname);
    return selectedItem ? [selectedItem.key] : ["1"];
  };

  // const getSelectedKeys = () => {
  //   const pathname = location.pathname;

  //   const selectedBatch = assignBatches.map(
  //     (item, ind) =>
  //       `/tutor/dashboard/batches/${item.m_batch_id}` === pathname && ind + 3
  //   );
  //   const selectedItem = items.find((item) => item.path === pathname);

  //   return selectedBatch
  //     ? [selectedBatch.key]
  //     : selectedItem
  //     ? [selectedItem.key]
  //     : ["1"];
  // };
  return (
    <Layout
      style={{ height: "100%", marginRight: "8px" }}
      className="border-t border-white"
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{ background: "#070756" }}
      >
        <div className="demo-logo-vertical" />
        <Menu
          className="bg-[#070756] text-white"
          defaultSelectedKeys={getSelectedKeys()}
          mode="inline"
        >
          {items.map((item) =>
            item.children ? (
              <Menu.SubMenu key={item.key} title={item.label} icon={item.icon}>
                {item.children.map((childItem) => (
                  <Menu.Item key={childItem.key}>
                    <Link
                      to={childItem.path}
                      onClick={() => {
                        localStorage.setItem("batch_name", childItem.label);
                      }}
                    >
                      {childItem.label}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ) : (
              <Menu.Item
                className="text-white  hover:text-gray-200"
                key={item.key}
                icon={item.icon}
              >
                <Link className="" to={item.path}>
                  {item.label}
                </Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </Sider>
    </Layout>
  );
};

export default TutorSideBar;
