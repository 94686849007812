import React, { useState } from "react";
import { Datepicker } from "@meinefinsternis/react-horizontal-date-picker";
import { enUS } from "date-fns/locale";
import { isSameDay } from "date-fns";
import { formatDate } from "../../service/utils";

import "../../App.css";

const Calendar = ({ getData }) => {
  const [active, setActive] = useState(false);

  const today = new Date();
  const futureDate = new Date(today);
  // Add 10 days to the current date
  futureDate.setDate(today.getDate() - 10);
  const futureDatevalue = new Date(today);
  futureDatevalue.setDate(today.getDate() + 30);

  // disable sundays
  let sundays = [new Date(2024, 0, 7)]; // January 7th, 2024
  // Loop to find Sundays until the end of the year
  while (sundays[sundays.length - 1].getFullYear() === 2024) {
    let nextSunday = new Date(sundays[sundays.length - 1]); // Get the last Sunday
    nextSunday.setDate(nextSunday.getDate() + 7); // Add 7 days to get the next Sunday
    sundays.push(nextSunday); // Push the next Sunday to the array
  }

  // disable sundays

  const [date, setDate] = useState({
    startValue: today,
    endValue: new Date(2024, 9, 30), //enter the end date of batch, last value of selected range
    rangeDates: [],
    startDate: futureDate,
    endDate: new Date(2024, 9, 30),
  });

  const handleChange = (d) => {
    const [startValue] = d;
    let formattedDate;
    if (startValue === null) {
      formattedDate = formatDate(today);
    } else {
      formattedDate = formatDate(startValue);
    }
    if (startValue < today) {
      setActive(true);
      getData(formattedDate);
    }
  };
  return (
    <Datepicker
      style={{ backgroundColor: "blue" }}
      className={`.or:not(.FC)  .or:not(.Lx) focus-visible ${
        active ? "FC _3n" : ""
      }`}
      onChange={handleChange}
      locale={enUS}
      startValue={date.startValue}
      endValue={date.endValue}
      startDate={date.startDate}
      endDate={date.endDate}
      disabledDates={sundays}
    />
  );
};

export default Calendar;
