import { createSlice } from "@reduxjs/toolkit";

const assignBatchesStudentSlice = createSlice({
  name: "assign Batches Student",
  initialState: {
    assignBatchesStudent: [],
  },
  reducers: {
    setAssignBatchesStudent(state, action) {
      state.assignBatchesStudent = action.payload;
    },
  },
});

export const { setAssignBatchesStudent } = assignBatchesStudentSlice.actions;
export default assignBatchesStudentSlice.reducer;
